export const useTranslate = () => {
  const { t, d, n, locale, setLocale } = useI18n();

  const findKey = (list: any) => {
    var lang = locale.value.toString();
    for (const item of list) {
      //console.log("nyelv:", locale.value);
      if (lang in item) {
        return item[`${lang}`];
      }
    }
    return null;
  };

  return { findKey };
};
