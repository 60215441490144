import { defineStore } from "pinia";
//const runtimeConfig = useRuntimeConfig();

export const useMainStore = defineStore("vsz", {
  // a function that returns a fresh state
  state: () => ({
    actScrName: "",
    showPolicy: false,
    sub_pages: ["abuse-report", "quality", "policy"],

    policyTerms: [
      {
        hu: {
          html: `<div class="ma-8 ma-md-16">
            <h3>Érvényességi dátum: 2023.08.01</h3>
            <br />
            <h2>Adatvédelmi irányelv:</h2>
            <p class="text-justify">
              Mi, a Varga Szárnyas Kft., elkötelezettek vagyunk az Ön magánéletének védelme és személyes adatai biztonságának garantálása iránt. Ez az Adatvédelmi irányelv magyarázza, hogy hogyan gyűjtjük, használjuk és tároljuk az adatait, amikor ellátogat weboldalunkra, és hozzájárul, hogy kapcsolatba lépjünk Önnel űrlapunk segítségével. Az adatok megadásával elfogadja ennek az irányelvnek a feltételeit.
            </p>
            <br />
            <p>
              <strong>Általunk gyűjtött információk: </strong> Amikor weboldalunkra látogat, és kitölti a kapcsolat űrlapot, az alábbi információkat gyűjthetjük:
            </p>
            <ul class="ml-5">
              <li>A neve</li>
              <li>Az e-mail címe</li>
              <li>A telefonszáma (opcionális)</li>
              <li>
                Bármilyen más információ, amit önként megad a üzenet mezőben
              </li>
            </ul>
            <br />        
            <p>
              <strong>Információk felhasználása: </strong> A gyűjtött információkat válaszolásra, kért információk biztosítására és segítség nyújtására használjuk termékeink vagy szolgáltatásainkkal kapcsolatban. Az adatait kizárólag arra a célra használjuk fel, amelyhez megadta őket, és nem adjuk át vagy értékesítjük harmadik feleknek az Ön engedélye nélkül.
            </p>
            <br />
            <p>
              <strong>Google Analytics: </strong> Weboldalunk Google Analytics segítségével elemzi a weboldal forgalmát és optimalizálja a felhasználói élményt. A Google Analytics süti fájlokat és hasonló technológiákat használ az Ön interakcióinak azonosításához a weboldalunkkal. Ezek az információk anonimizáltak, és statisztikai jelentések készülnek weboldalunk használatáról. A weboldalunk további használatával hozzájárul a sütik használatához és az adatgyűjtéshez, amint azt a Süti szabályzatunkban leírtuk.
            </p>
            <br />
            <p>
              <strong>Adattárolás és biztonság:</strong> Megfelelő intézkedéseket teszünk az adatok védelme és biztonsága érdekében. Az információi biztonságosan tároljuk, és annyi ideig megőrizzük, ameddig a gyűjtés céljának megfelelő, vagy amennyire a jogszabályok előírják.
            </p>
            <br />
            <p>
              <strong>Ön jogai:</strong> Joga van hozzáférni, frissíteni vagy kérni személyes adatainak törlését az adataink közül. Ha bármelyik jogát gyakorolni kívánja, lépjen kapcsolatba velünk az irányelv végén megadott információk segítségével.
            </p>
            <br />
            <h2>Használati feltételek:</h2>
            <p>Az oldalunkhoz való hozzáféréssel és használatával elfogadod az alábbi Használati feltételeket:</p>
            <br />            
            <p><strong>Szellemi tulajdon: </strong>Az oldal tartalma, ideértve a szövegeket, képeket, grafikákat, logókat és más anyagokat, a Varga Szárnyas Kft. tulajdonát képezi, és szerzői jog, védjegy és más szellemi tulajdonvédelmi törvények védik.</p>
            <br />            
            <p><strong>Információk felhasználása: </strong>Az oldal tartalmát és információit kizárólag személyes, nem kereskedelmi célra használhatod. A tartalom jogosulatlan felhasználása, másolása vagy terjesztése szigorúan tilos.</p>
            <br />            
            <p><strong>Külső weboldalakra mutató linkek: </strong>Az oldalunk tartalmazhat linkeket harmadik fél weboldalaira a kényelem érdekében. Azonban nem vállalunk felelősséget ezeknek az külső oldalaknak a tartalmáért vagy adatvédelmi gyakorlatáért. Kérjük, ellenőrizd az ő adatvédelmi irányelveiket és használati feltételeiket.</p>
            <br />            
            <p><strong>Adatvédelmi irányelvek és Használati feltételek változásai: </strong>Fenntartjuk a jogot, hogy bármikor frissítsük vagy módosítsuk az Adatvédelmi irányelveket és a Használati feltételeket. Az ilyen változásokat közzétesszük az oldalunkon, és az oldal használatának folytatása az új feltételek elfogadását jelenti.</p>
            <br /><h2>Vegye fel velünk a kapcsolatot:</h2>

            <p>Ha kérdése, aggálya vagy kérése van az Adatvédelmi irányelveinkkel vagy a Használati feltételekkel kapcsolatban, kérjük, lépjen kapcsolatba velünk az alábbi elérhetőségeinken:</p>
            <p class="mt-7 op-8 mb-0 text-black text-h5">Varga Szárnyas Kft.</p>
            <p class="op-8 mb-0 text-black">Kossuth Lajos u. 254.</p>
            <p class="op-8 mb-0 text-black">Becsehely, 8866</p>
            <p class="op-8 mb-0 text-black">Magyarország</p>
            <p class="mt-7 op-8 mb-0 text-black">+36 93 900 400</p>
            <p class="op-8 mb-8 text-black">rendeles@vargaszarnyas.hu</p>

            <p>Az oldalunk használatával és az információk megadásával elismeri, hogy elolvasta és megértette az Adatvédelmi irányelveket és a Használati feltételeket, és elfogadja azok feltételeit.</p>
            </div>`,
        },
      },
      {
        en: {
          html: `<div class="ma-8 ma-md-16">
              <h3>Effective Date: 01.08.2023</h3>
          <br />
          <h2>Privacy Policy:</h2>
          <p>
            We at Varga Szárnyas Kft. are committed to protecting your privacy and
            ensuring the security of your personal information. This Privacy
            Policy explains how we collect, use, and store your data when you
            visit our landing page and agree to contact us through our form. By
            providing your information, you agree to the terms of this policy.
          </p>
          <br />
          <p>
            <strong>Information We Collect:</strong> When you visit our landing
            page and fill out the contact form, we may collect the following
            information:
          </p>
          <ul class="ml-5">
            <li>Your name</li>
            <li>Your email address</li>
            <li>Your phone number (optional)</li>
            <li>
              Any other information you voluntarily provide in the message field
            </li>
          </ul>
          <br />

          <p>
            <strong>Use of Information:</strong> We use the information collected
            to respond to your inquiries, provide you with the requested
            information, and offer assistance regarding our products or services.
            Your data will be used solely for the purpose for which it was
            provided and will not be shared or sold to third parties without your
            consent.
          </p>
          <br />
          <p>
            <strong>Google Analytics:</strong> Our landing page uses Google
            Analytics to analyze website traffic and optimize your experience.
            Google Analytics uses cookies and similar technologies to collect data
            about your interactions with our website. This information is
            anonymized and used to generate statistical reports about website
            usage. By continuing to use our website, you consent to the use of
            cookies and data collection as described in our Cookie Policy.
          </p>
          <br />
          <p>
            <strong>Data Storage and Security:</strong> We take appropriate
            measures to protect your data and ensure its security. Your
            information will be stored securely and will be retained for as long
            as necessary to fulfill the purpose for which it was collected or as
            required by law.
          </p>
          <br />
          <p>
            <strong>Your Rights:</strong> You have the right to access, update, or
            request the deletion of your personal information from our records. If
            you wish to exercise any of these rights, please contact us using the
            information provided at the end of this policy.
          </p>
          <br />
          <h2>Terms of Use:</h2>
          <p>
            By accessing and using our landing page, you agree to the following
            Terms of Use:
          </p>
          <br />          
          <p>
            <strong>Intellectual Property:</strong>
            All content, including text, images, graphics, logos, and other
            materials, on this landing page is the property of Varga Szárnyas Kft.
            and is protected by copyright, trademark, and other intellectual
            property laws.
          </p>
          <br />          
          <p>
            <strong>Use of Information:</strong>
            You may use the information and content on this landing page for
            personal, non-commercial purposes only. Any unauthorized use,
            reproduction, or distribution of the content is strictly prohibited.
          </p>
          <br />          
          <p>
            <strong>Links to Third-Party Websites:</strong>
            Our landing page may contain links to third-party websites for your
            convenience. However, we do not endorse or assume any responsibility
            for the content or privacy practices of these external sites. Please
            review their respective Privacy Policies and Terms of Use.
          </p>
          <br />
          
          <p>
            <strong>Changes to Privacy Policy and Terms of Use:</strong>
            We reserve the right to update or modify this Privacy Policy and Terms
            of Use at any time. Any changes will be posted on this landing page,
            and your continued use of the website after the changes will signify
            your acceptance of the updated policies.
          </p>
          <br />
  
          <h2>Contact Us:</h2>
  
          <p>
            If you have any questions, concerns, or requests regarding our Privacy
            Policy or Terms of Use, please contact us at:
          </p>
          <p class="mt-7 op-8 mb-0 text-black text-h5">Varga Szárnyas Kft.</p>
          <p class="op-8 mb-0 text-black">Kossuth Lajos u. 254.</p>
          <p class="op-8 mb-0 text-black">Becsehely, 8866</p>
          <p class="op-8 mb-0 text-black">Magyarország</p>
          <p class="mt-7 op-8 mb-0 text-black">+36 93 900 400</p>
          <p class="op-8 mb-8 text-black">rendeles@vargaszarnyas.hu</p>
  
          <p>
            By using our landing page and providing your information, you
            acknowledge that you have read and understood this Privacy Policy and
            Terms of Use and agree to be bound by its terms.
          </p>
        </div>`,
        },
      },
      {
        de: {
          html: `<div class="ma-8 ma-md-16">
          <h3>Wirksamkeitsdatum: 01.08.2023</h3>
          <br />
          <h2>Datenschutzerklärung:</h2>
          <p>
            Wir von Varga Szárnyas Kft. sind bestrebt, Ihre Privatsphäre zu schützen und die Sicherheit Ihrer persönlichen Informationen zu gewährleisten. Diese Datenschutzerklärung erläutert, wie wir Ihre Daten erfassen, verwenden und speichern, wenn Sie unsere Landingpage besuchen und sich damit einverstanden erklären, uns über unser Formular zu kontaktieren. Durch die Bereitstellung Ihrer Informationen stimmen Sie den Bestimmungen dieser Richtlinie zu.
          </p>
          <br />
          <p>
            <strong>Informationen, die wir sammeln:</strong> Wenn Sie unsere Landingpage besuchen und das Kontaktformular ausfüllen, können wir folgende Informationen erfassen:
          </p>
          <ul class="ml-5">
            <li>Ihr Name</li>
            <li>Ihre E-Mail-Adresse</li>
            <li>Ihre Telefonnummer (optional)</li>
            <li>
              Alle anderen Informationen, die Sie freiwillig im Nachrichtenfeld angeben
            </li>
          </ul>
          <br />
          <p>
          <strong>Verwendung von Informationen:</strong> Wir verwenden die gesammelten Informationen, um auf Ihre Anfragen zu antworten, Ihnen die angeforderten Informationen zur Verfügung zu stellen und Unterstützung in Bezug auf unsere Produkte oder Dienstleistungen anzubieten. Ihre Daten werden ausschließlich für den Zweck verwendet, für den sie bereitgestellt wurden, und werden ohne Ihre Zustimmung nicht an Dritte weitergegeben oder verkauft.
        </p>
        <br />
        <p>
          <strong>Google Analytics:</strong> Unsere Landingpage verwendet Google Analytics, um den Website-Verkehr zu analysieren und Ihr Erlebnis zu optimieren. Google Analytics verwendet Cookies und ähnliche Technologien, um Daten über Ihre Interaktionen mit unserer Website zu sammeln. Diese Informationen werden anonymisiert und zur Erstellung statistischer Berichte über die Nutzung der Website verwendet. Durch die weitere Nutzung unserer Website stimmen Sie der Verwendung von Cookies und der Datensammlung gemäß unserer Cookie-Richtlinie zu.
        </p>
        <br />
        <p>
          <strong>Datenspeicherung und Sicherheit:</strong> Wir ergreifen angemessene Maßnahmen zum Schutz Ihrer Daten und stellen ihre Sicherheit sicher. Ihre Informationen werden sicher gespeichert und für die Dauer aufbewahrt, die zur Erfüllung des Zwecks, für den sie erfasst wurden, oder nach geltendem Recht erforderlich ist.
        </p>
        <br />
        <p>
          <strong>Ihre Rechte:</strong> Sie haben das Recht, auf Ihre persönlichen Informationen in unseren Aufzeichnungen zuzugreifen, sie zu aktualisieren oder deren Löschung zu beantragen. Wenn Sie eines dieser Rechte ausüben möchten, kontaktieren Sie uns bitte über die am Ende dieser Richtlinie angegebenen Informationen.
        </p>
        <br />  
        <h1>Nutzungsbedingungen:</h1>
        <p>
          Durch den Zugriff und die Nutzung unserer Landing Page stimmen Sie den folgenden Nutzungsbedingungen zu:
        </p>
        <br />
        <h2>Geistiges Eigentum:</h2>
        <p>
          Alle Inhalte, einschließlich Texte, Bilder, Grafiken, Logos und andere Materialien auf dieser Landing Page sind Eigentum von Varga Szárnyas Kft. und durch Urheberrecht, Markenrecht und andere geistige Eigentumsrechte geschützt.
        </p>
        <br />
        <h2>Verwendung von Informationen:</h2>
        <p>
          Sie dürfen die Informationen und Inhalte auf dieser Landing Page nur für persönliche, nicht-kommerzielle Zwecke verwenden. Jegliche unbefugte Verwendung, Reproduktion oder Verbreitung des Inhalts ist strengstens untersagt.
        </p>
        <br />
        <h2>Links zu Websites Dritter:</h2>
        <p>
          Unsere Landing Page kann Links zu Websites Dritter enthalten, die Ihnen als Bequemlichkeit dienen. Wir übernehmen jedoch keine Verantwortung für den Inhalt oder die Datenschutzpraktiken dieser externen Websites. Bitte überprüfen Sie deren jeweilige Datenschutzrichtlinien und Nutzungsbedingungen.
        </p>
        <br />
        <h2>Änderungen der Datenschutzrichtlinie und Nutzungsbedingungen:</h2>
        <p>
          Wir behalten uns das Recht vor, diese Datenschutzrichtlinie und Nutzungsbedingungen jederzeit zu aktualisieren oder zu ändern. Änderungen werden auf dieser Landing Page veröffentlicht, und Ihre fortgesetzte Nutzung der Website nach den Änderungen bedeutet Ihre Zustimmung zu den aktualisierten Richtlinien.
        </p>
        <br />
        <h1>Kontakt:</h1>
        <p>
          Wenn Sie Fragen, Bedenken oder Anfragen bezüglich unserer Datenschutzrichtlinie oder Nutzungsbedingungen haben, kontaktieren Sie uns bitte unter:
        </p>
        <p class="mt-7 op-8 mb-0 text-black text-h5">Varga Szárnyas Kft.</p>
        <p class="op-8 mb-0 text-black">Kossuth Lajos u. 254.</p>
        <p class="op-8 mb-0 text-black">Becsehely, 8866</p>
        <p class="op-8 mb-0 text-black">Ungarn</p>
        <p class="mt-7 op-8 mb-0 text-black">+36 93 900 400</p>
        <p class="op-8 mb-8 text-black">rendeles@vargaszarnyas.hu</p>

        <p>
          Durch die Nutzung unserer Landing Page und die Bereitstellung Ihrer Informationen bestätigen Sie, dass Sie diese Datenschutzrichtlinie und Nutzungsbedingungen gelesen und verstanden haben und sich an ihre Bedingungen binden.
        </p>
      </div>`,
        },
      },
      {
        hr: {
          html: `<div class="ma-8 ma-md-16">
                <h3>Datum stupanja na snagu: 01.08.2023</h3>
                <br />
                <h2>Pravila o privatnosti:</h2>
                <p>
                  Mi, tvrtka Varga Szárnyas Kft., posvećeni smo zaštiti vaše privatnosti i
                  osiguranju sigurnosti vaših osobnih podataka. Ova Pravila o privatnosti
                  objašnjavaju kako prikupljamo, koristimo i pohranjujemo vaše podatke
                  kada posjetite našu landing stranicu i pristajete stupiti u kontakt s
                  nama putem našeg obrasca. Pružanjem vaših informacija, slažete se s
                  uvjetima ovih pravila.
                </p>
                <br />
                <p>
                  <strong>Informacije koje prikupljamo:</strong> Kada posjetite našu
                  landing stranicu i ispunite kontakt obrazac, možemo prikupiti sljedeće
                  informacije:
                </p>
                <ul class="ml-5">
                  <li>Vaše ime</li>
                  <li>Vaša e-mail adresa</li>
                  <li>Vaš broj telefona (opcionalno)</li>
                  <li>
                    Bilo koje druge informacije koje dobrovoljno navedete u polju za
                    poruku
                  </li>
                </ul>
                <br />
                <p>
                <strong>Korištenje informacija:</strong> Prikupljene informacije
                koristimo kako bismo odgovorili na vaše upite, pružili vam tražene
                informacije i pružili pomoć u vezi s našim proizvodima ili uslugama.
                Vaši podaci bit će korišteni isključivo u svrhu za koju su pruženi i
                neće se dijeliti ili prodavati trećim stranama bez vašeg pristanka.
              </p>
              <br />
              <p>
                <strong>Google Analytics:</strong> Naša landing stranica koristi Google
                Analytics za analizu prometa na web mjestu i optimizaciju vašeg
                iskustva. Google Analytics koristi kolačiće i slične tehnologije za
                prikupljanje podataka o vašim interakcijama s našom web stranicom. Ove
                informacije su anonimizirane i koriste se za generiranje statističkih
                izvještaja o korištenju web stranice. Nastavkom korištenja naše web
                stranice, pristajete na upotrebu kolačića i prikupljanje podataka kako
                je opisano u našem Pravilu o kolačićima.
              </p>
              <br />
              <p>
                <strong>Pohrana i sigurnost podataka:</strong> Poduzimamo odgovarajuće
                mjere kako bismo zaštitili vaše podatke i osigurali njihovu sigurnost.
                Vaše informacije bit će sigurno pohranjene i bit će zadržane onoliko
                dugo koliko je potrebno za ispunjenje svrhe za koju su prikupljene ili
                prema zakonskim zahtjevima.
              </p>
              <br />
              <p>
                <strong>Vaša prava:</strong> Imate pravo pristupiti, ažurirati ili
                zatražiti brisanje vaših osobnih podataka iz naših evidencija. Ako želite
                ostvariti bilo koje od ovih prava, molimo kontaktirajte nas putem
                informacija navedenih na kraju ovih pravila.
              </p>
              <br />  
                <h1>Pravila korištenja:</h1>
                <p>
                  Pristupom i korištenjem naše početne stranice, slažete se s
                  sljedećim Pravilima korištenja:
                </p>
                <br />
                <h2>Intelektualno vlasništvo:</h2>
                <p>
                  Svi sadržaji, uključujući tekst, slike, grafike, logotipe i druge
                  materijale na ovoj početnoj stranici vlasništvo su Varga Szárnyas Kft.
                  i zaštićeni su autorskim pravima, zaštitnim znakovima i drugim
                  pravima intelektualnog vlasništva.
                </p>
                <br />
                <h2>Upotreba informacija:</h2>
                <p>
                  Informacije i sadržaj s ove početne stranice smijete koristiti samo u
                  osobne, nekomercijalne svrhe. Svaka neovlaštena upotreba,
                  reprodukcija ili distribucija sadržaja strogo je zabranjena.
                </p>
                <br />
                <h2>Poveznice prema web stranicama trećih strana:</h2>
                <p>
                  Naša početna stranica može sadržavati poveznice prema web stranicama
                  trećih strana radi vaše pogodnosti. Međutim, mi ne podržavamo niti
                  preuzimamo odgovornost za sadržaj ili prakse privatnosti tih vanjskih
                  stranica. Molimo vas da pregledate njihove odgovarajuće Politike
                  privatnosti i Pravila korištenja.
                </p>
                <br />
                <h2>Promjene u Politici privatnosti i Pravilima korištenja:</h2>
                <p>
                  Zadržavamo pravo da u bilo kojem trenutku ažuriramo ili izmijenimo
                  ovu Politiku privatnosti i Pravila korištenja. Sve promjene bit će
                  objavljene na ovoj početnoj stranici, a vaše nastavljeno korištenje
                  web stranice nakon promjena značit će vaše prihvaćanje ažuriranih
                  pravila.
                </p>
                <br />
                <h1>Kontaktirajte nas:</h1>
                <p>
                  Ako imate pitanja, brige ili zahtjeve vezane uz našu Politiku
                  privatnosti ili Pravila korištenja, kontaktirajte nas na:
                </p>
                <p class="mt-7 op-8 mb-0 text-black text-h5">Varga Szárnyas Kft.</p>
                <p class="op-8 mb-0 text-black">Kossuth Lajos u. 254.</p>
                <p class="op-8 mb-0 text-black">Becsehely, 8866</p>
                <p class="op-8 mb-0 text-black">Magyarország</p>
                <p class="mt-7 op-8 mb-0 text-black">+36 93 900 400</p>
                <p class="op-8 mb-8 text-black">rendeles@vargaszarnyas.hu</p>

                <p>
                  Korištenjem naše početne stranice i pružanjem vaših informacija,
                  potvrđujete da ste pročitali i razumjeli ovu Politiku privatnosti i
                  Pravila korištenja te pristajete biti obvezani njenim odredbama.
                </p>
              </div>`,
        },
      },
      {
        sk: {
          html: `<div class="ma-8 ma-md-16">
                <h3>Veljaven datum: 01.08.2023</h3>
                <br />
                <h2>Pravilnik o zasebnosti:</h2>
                <p>
                  V podjetju Varga Szárnyas Kft. smo zavezani k varovanju vaše zasebnosti in zagotavljanju varnosti vaših osebnih podatkov. Ta pravilnik o zasebnosti pojasnjuje, kako zbiramo, uporabljamo in shranjujemo vaše podatke, ko obiščete našo pristajalno stran in se strinjate, da stopite v stik z nami prek našega obrazca. Z zagotovitvijo vaših informacij se strinjate s pogoji tega pravilnika.
                </p>
                <br />
                <p>
                  <strong>Zbrane informacije:</strong> Ko obiščete našo pristajalno stran in izpolnite kontaktni obrazec, lahko zberemo naslednje informacije:
                </p>
                <ul class="ml-5">
                  <li>Vaše ime</li>
                  <li>Vaš e-poštni naslov</li>
                  <li>Vaš telefonski številka (neobvezno)</li>
                  <li>
                    Druge informacije, ki jih prostovoljno posredujete v polju za sporočilo
                  </li>
                </ul>
                <br />
                <p>
                <strong>Uporaba informacij:</strong> Zbrane informacije uporabljamo za odgovor na vaša vprašanja, zagotavljanje zahtevanih informacij in pomoč pri naših izdelkih ali storitvah. Vaši podatki bodo uporabljeni izključno za namen, za katerega so bili posredovani, in ne bodo deljeni ali prodani tretjim osebam brez vašega soglasja.
              </p>
                <br />
                <p>
                  <strong>Google Analytics:</strong> Naša pristajalna stran uporablja Google Analytics za analizo prometa na spletnem mestu in optimizacijo vaše izkušnje. Google Analytics uporablja piškotke in podobne tehnologije za zbiranje podatkov o vaših interakcijah s spletnim mestom. Te informacije so anonimizirane in se uporabljajo za ustvarjanje statističnih poročil o uporabi spletnega mesta. Z nadaljnjo uporabo naše spletne strani soglašate z uporabo piškotkov in zbiranjem podatkov, kot je opisano v našem Pravilniku o piškotkih.
                </p>
                <br />
                <p>
                  <strong>Shranjevanje in varnost podatkov:</strong> Ustrezne ukrepe sprejemamo za zaščito vaših podatkov in zagotavljanje njihove varnosti. Vaše informacije bodo varno shranjene in jih bomo hranili tako dolgo, kot je potrebno za izpolnitev namena, za katerega so bile zbrane, ali kot to zahteva zakonodaja.
                </p>
                <br />
                <p>
                  <strong>Vaše pravice:</strong> Imate pravico dostopati, posodobiti ali zahtevati izbris svojih osebnih podatkov iz naših evidenc. Če želite uveljaviti katero od teh pravic, nas kontaktirajte prek informacij, ki so navedene na koncu tega pravilnika.
                </p>
                <br />      
                <h1>Podmienky používania:</h1>
                <p>
                  Tým, že pristupujete a používate našu vstupnú stránku, súhlasíte s nasledujúcimi
                  Podmienkami používania:
                </p>
                <br />
                <h2>Právne vlastníctvo:</h2>
                <p>
                  Všetok obsah, vrátane textu, obrázkov, grafiky, loga a ďalších
                  materiálov, na tejto vstupnej stránke je majetkom Varga Szárnyas Kft.
                  a je chránený autorským právom, ochrannou známkou a inými právami duševného vlastníctva.
                </p>
                <br />
                <h2>Využitie informácií:</h2>
                <p>
                  Obsah a informácie na tejto vstupnej stránke môžete používať iba pre osobné,
                  nekomerčné účely. Akékoľvek neoprávnené použitie, reprodukcia alebo distribúcia obsahu je prísne zakázaná.
                </p>
                <br />
                <h2>Odkazy na webové stránky tretích strán:</h2>
                <p>
                  Naša vstupná stránka môže obsahovať odkazy na webové stránky tretích strán
                  pre vaše pohodlie. Avšak nezaručujeme ani nepreberáme žiadnu zodpovednosť
                  za obsah alebo postupy ochrany osobných údajov týchto externých stránok. 
                  Prosím, prečítajte si ich príslušné Zásady ochrany osobných údajov a Podmienky používania.
                </p>
                <br />
                <h2>Zmeny Zásad ochrany osobných údajov a Podmienok používania:</h2>
                <p>
                  Vyhradzujeme si právo aktualizovať alebo upraviť tieto Zásady ochrany osobných údajov
                  a Podmienky používania kedykoľvek. Všetky zmeny budú zverejnené na tejto vstupnej stránke
                  a váš ďalší používania webovej stránky po zmene znamená váš súhlas s aktualizovanými pravidlami.
                </p>
                <br />
                <h1>Kontaktujte nás:</h1>
                <p>
                  Ak máte akékoľvek otázky, obavy alebo požiadavky týkajúce sa našich Zásad ochrany osobných údajov
                  alebo Podmienok používania, prosím, kontaktujte nás na:
                </p>
                <p class="mt-7 op-8 mb-0 text-black text-h5">Varga Szárnyas Kft.</p>
                <p class="op-8 mb-0 text-black">Kossuth Lajos u. 254.</p>
                <p class="op-8 mb-0 text-black">Becsehely, 8866</p>
                <p class="op-8 mb-0 text-black">Magyarország</p>
                <p class="mt-7 op-8 mb-0 text-black">+36 93 900 400</p>
                <p class="op-8 mb-8 text-black">rendeles@vargaszarnyas.hu</p>

                <p>
                  Používaním našej vstupnej stránky a poskytovaním vašich informácií potvrdzujete,
                  že ste si prečítali a pochopili tieto Zásady ochrany osobných údajov a Podmienky používania
                  a súhlasíte s ich dodržiavaním.
                </p>
              </div>`,
        },
      },
    ],

    headers: [
      {
        hu: [
          {
            title: "Kezdőlap",
            href: "home",
          },
          {
            title: "Rólunk",
            href: "about",
          },
          {
            title: "Szolgáltatások",
            href: "service",
          },
          {
            title: "Termékeink",
            href: "product",
          },
          {
            title: "Kapcsolat",
            href: "contact",
            // sub_menu: [
            //   {
            //     title: 'Elérhetőségeink',
            //     href: "/#contact"
            //   },
            //   {
            //     title: 'Belső Visszaélés-bejelentési Rendszer',
            //     href: "/abuse-report"
            //   },

            // ],
          },
        ],
      },
      {
        en: [
          {
            title: "Home",
            href: "home",
          },
          {
            title: "About Us",
            href: "about",
          },
          {
            title: "Services",
            href: "service",
          },
          {
            title: "Products",
            href: "product",
          },
          {
            title: "Contact",
            href: "contact",
            // sub_menu: [
            //   {
            //     title: "Contact Information",
            //     href: "/#contact"
            //   },
            //   {
            //     title: "Internal Abuse Reporting System",
            //     href: "/abuse-report"
            //   }
            // ]
          },
        ],
      },
      {
        de: [
          {
            title: "Startseite",
            href: "home",
          },
          {
            title: "Über uns",
            href: "about",
          },
          {
            title: "Dienstleistungen",
            href: "service",
          },
          {
            title: "Produkte",
            href: "product",
          },
          {
            title: "Kontakt",
            href: "contact",
            // "sub_menu": [
            //   {
            //     "title": "Kontaktinformation",
            //     "href": "/#kontakt"
            //   },
            //   {
            //     "title": "Internes Missbrauchsmeldungssystem",
            //     "href": "/missbrauchsmeldung"
            //   }
            // ]
          },
        ],
      },
      {
        sk: [
          {
            title: "Domov",
            href: "home",
          },
          {
            title: "O nás",
            href: "about",
          },
          {
            title: "Služby",
            href: "service",
          },
          {
            title: "Produkty",
            href: "product",
          },
          {
            title: "Kontakt",
            href: "contact",
            // "sub_menu": [
            //   {
            //     "title": "Kontaktné údaje",
            //     "href": "/#kontakt"
            //   },
            //   {
            //     "title": "Interný systém hlásenia zneužívania",
            //     "href": "/hlasenie-zneuzivania"
            //   }
            // ]
          },
        ],
      },
      {
        hr: [
          {
            title: "Početna",
            href: "home",
          },
          {
            title: "O nama",
            href: "about",
          },
          {
            title: "Usluge",
            href: "service",
          },
          {
            title: "Proizvodi",
            href: "product",
          },
          {
            title: "Kontakt",
            href: "contact",
            // "sub_menu": [
            //   {
            //     "title": "Kontakt informacije",
            //     "href": "/#kontakt"
            //   },
            //   {
            //     "title": "Interni sustav prijave zloupotrebe",
            //     "href": "/prijava-zloupotrebe"
            //   }
            // ]
          },
        ],
      },
    ],

    cookieText: [
      {
        hu: {
          desc:
            "Sütiket használunk a webhelyforgalom elemzéséhez és az Ön webhelyélményének optimalizálásához. A sütik használatának elfogadásával az adatai az összes többi felhasználó adataival együtt lesznek összesítve. További információkat arról, hogy a Google Analytics hogyan használ sütiket, és hogyan léphet ki azokból, a <a href='https://policies.google.com/?hl=hu-HU' target='_blank'>Google Adatvédelmi és Felhasználási Szabályzat oldalán</a> talál. Az oldalunk további használatával hozzájárul a sütik használatához.",
          accept: "Elfogad",
          deny: "Elutasít",
        },
        en: {
          desc:
            "We use cookies to analyze website traffic and optimize your website experience. By accepting our use of cookies, your data will be aggregated with all other user data. You can learn more about how Google Analytics uses cookies and how you can opt-out by visiting <a href='https://policies.google.com/?hl=en-US' target='_blank'>Google's Privacy & Terms page</a>. By continuing to use our website, you consent to the use of these cookies.",
          accept: "Accept",
          deny: "Reject",
        },
        de: {
          desc:
            "Wir verwenden Cookies, um den Website-Verkehr zu analysieren und Ihr Website-Erlebnis zu optimieren. Durch die Zustimmung zu unserer Verwendung von Cookies werden Ihre Daten mit allen anderen Benutzerdaten zusammengefasst. Weitere Informationen darüber, wie Google Analytics Cookies verwendet und wie Sie sich abmelden können, finden Sie auf der <a href='https://policies.google.com/?hl=de' target='_blank'>Google Datenschutz- und Nutzungsbedingungen</a> Seite. Durch die weitere Nutzung unserer Website stimmen Sie der Verwendung dieser Cookies zu.",
          accept: "Akzeptieren",
          deny: "Ablehnen",
        },
        sk: {
          desc:
            "Používame súbory cookies na analýzu návštevnosti webových stránok a optimalizáciu vášho zážitku s webovou stránkou. Ak prijmete používanie súborov cookies, vaše údaje budú zlúčené s ostatnými údajmi používateľov. Viac informácií o používaní súborov cookies spoločnosťou Google Analytics a možnosti odhlásenia nájdete na stránke <a href='https://policies.google.com/?hl=sk' target='_blank'>Zásady ochrany osobných údajov a podmienky spoločnosti Google</a>. Pokračovaním v používaní našej webovej stránky vyjadrujete súhlas s používaním týchto súborov cookies.",
          accept: "Súhlasím",
          deny: "Odmietam",
        },
        hr: {
          desc:
            "Koristimo kolačiće za analizu prometa na web stranici i optimizaciju vašeg iskustva na web stranici. Prihvaćanjem naše upotrebe kolačića, vaši podaci će biti agregirani s podacima svih drugih korisnika. Više informacija o tome kako Google Analytics koristi kolačiće i kako se možete odjaviti možete pronaći na <a href='https://policies.google.com/?hl=en-US' target='_blank'>Googleovoj stranici za privatnost i uvjete korištenja</a>. Nastavkom korištenja naše web stranice, pristajete na upotrebu ovih kolačića.",
          accept: "Prihvati",
          deny: "Odbij",
        },
      },
    ],

    welcome: [
      {
        hu: {
          title: "Rólunk",
          desc:
            "Az 1996-os alapítású családi vállalkozásunk hosszú utat járt be. A főleg kézi feldolgozással működő, naponta 400 csirkét feldolgozó üzemtől jutottunk el az óránként 4000 darabos kapacitásig. A kezdetektől fogva erős és egyre élesedő versenyhelyzet határozza meg működésünket. Ezért a hatékonyság és kiszámítható működés folyamatos fejlesztése, valamint a partnerigények minél teljesebb körű kiszolgálása alapértékünkké vált. Családi kézben összpontosul a naposcsibekeltetés, takarmánygyártás, baromfifeldolgozás, termékcsomagolás és logisztika. Mindig is a folyamatos fejlődés határozta meg munkánkat, a jövőben is ezen értékek mentén folytatjuk tevékenységünket.",
          quality: {
            title: "Minőségbiztosítás",
            desc2:
              "Ezen területen elsősorban kiváló, szakképzett munkatársainkra támaszkodunk. Szigorú sztenderdjeinknek köszönhető, hogy rendszeresen megfelelünk a multinacionális kiskereskedelmi áruházláncok auditelvárásainak, a HACCP-rendszer követelményeinek és kiváló minősítésű tanúsítvánnyal rendelkezünk az IFS értékelésében. <br />A termékek minőségének biztosítása mellett mindig kiemelt figyelmet fordítunk a stabil és transzparens pénzügyi háttér biztosítására is.",
            desc:
              "A Varga Szárnyas Kft. elkötelezett a magyar élelmiszer termékek elismertségének fokozásában, partnereink bizalmának növelésében, és hosszú távon a vállalat eredményes működésének garantálásában. Élelmiszerbiztonsági rendszerünk a legmagasabb szinten működik, hogy biztosítékot és bizonyítékot szolgáltasson mind az ügyfeleinknek, mind önmagunknak, szervezett és ellenőrzött folyamataink révén, miközben minden munkatársunk személyes felelősséget vállal az élelmiszerbiztonságért és az állandó minőségért.",
            images: ["/images/quality/image-001.png"],
            sub_page: "quality",
          },
          productionUnits: {
            title: "Termelőegységeink",
            desc:
              "Becsehely külterületén, a magyar-horvát határ mellett alakítottuk ki gyártóbázisunkat 5 hektáron. Egymás mellett található a két fő tevékenységi körünket, a baromfifeldolgozást és a takarmánygyártást kiszolgáló infrastruktúra. A szomszédos elhelyezkedésnek köszönhetően teljes kontroll alatt tartjuk működésünket. A versenyképesség érdekében történtek a közelmúlt fejlesztései, feldolgozóüzemünkben teljesen automata és adatvezérelt feldolgozósort alakítottunk ki, mind a takarmánygyártás, baromfifeldolgozás és csomagolás területén. Ugyanekkora hangsúlyt fektetünk a digitalizációra és a hatékony szervezeti működés kialakítására.",
            images: [],
          },
          timeline: {
            title: "Idővonal",
            desc:
              "Kezdetben csupán a környező települések igényét szolgáltuk ki. Az évek során egyre szélesebb körben Magyarországon, majd az exportpiacokon is megjelentünk. A baromfitermelésünk értékének 2/3-a az exportpiacokon talál vevőre. Jelenleg Ausztria, Szlovákia, Románia, Horvátország, Szlovénia, Olaszország a fő exportpiacunk.",

            items: [
              {
                year: 1996,
                text:
                  "Megalakul a Varga-Szárnyas Kft. a zala megyei Tótszerdahelyen.",
              },
              {
                year: 2001,
                text:
                  "Átépítés, üzembővítés, cégünk átáll az európai uniós normákra.",
              },
              {
                year: 2004,
                text: "Létrehozzuk a takarmányüzemet.",
              },
              {
                year: 2008,
                text: "50 százalékos kapacitásbővítést hajtunk végre.",
              },
              {
                year: 2011,
                text:
                  "Zöldmezős beruházás keretében új feldolgozóüzemet építünk.",
              },
              {
                year: 2019,
                text:
                  "Elérjük a teljes technológiai modernizációt, új csomagolócsarnokot építünk. Cégünk dinamikusan növekvő,nettó árbevétele 6,3 milliárd forint.",
              },
              {
                year: 2021,
                text:
                  "Generációváltás, management kialalkítása, IT fejlesztések, felkészülés a heti 120-130 ezer darab csirke feldolgozására",
              },
            ],
          },
        },
      },
      {
        en: {
          title: "About Us",
          desc:
            "Our family business, founded in 1996, has come a long way. From a small-scale operation processing 400 chickens manually each day, we have now reached a capacity of 4000 units per hour. Since the beginning, our operations have been shaped by strong and ever-intensifying competition. Consequently, we have made efficiency and predictable functioning our core values, continuously striving to meet the comprehensive needs of our partners. With a family-oriented approach, we focus on day-old chick rearing, feed production, poultry processing, product packaging, and logistics. Our work has always been defined by continuous improvement, and we will continue to operate based on these principles in the future.",
          quality: {
            title: "Quality assurance",
            desc:
              "Varga Szárnyas Kft. is committed to increasing the recognition of Hungarian food products, building trust with our partners, and ensuring the long-term success of the company. Our food safety system operates at the highest level, providing assurance and evidence to both our customers and ourselves through organized and controlled processes, while each of our employees takes personal responsibility for food safety and consistent quality.",
            desc2:
              "We primarily rely on our highly skilled professionals in this field. Our strict standards enable us to regularly satisfy the audit requirements of multinational retail chains, HACCP, and also possess two IFS certificates with an excellent score. Beyond producing high-quality products, we also prioritize having a stable and transpar- ent financial operation.",
            images: ["/images/quality/image-001.png"],
            sub_page: "quality",
          },
          productionUnits: {
            title: "Production facilities",
            desc:
              "Our production facilities have been established on a 5-hectare area outside the municipal boundaries of Becsehely, close to the Hungarian- Croatian border. Facilities hosting our two main scopes of activities, poultry processing and the production of feed are located next to each other. Thanks to the adjacent locations, operation is under full control. Recent developments have taken place to increase our competitive advantage, a fully automated and data-driven processing line has been installed in our processing plant, both in the fields of feed production, poultry processing and packaging.",
            images: [],
          },
          timeline: {
            title: "Timeline",
            desc:
              "We started by serving the needs of neighboring settlements. Over the years our operation was extended to the territory of Hungary and we finally entered export markets. 2/3 of our poultry products’ value is sold to customers abroad. Currently our main export markets include Austria, Slovakia, Romania, Croatia, Slovenia and Italy.",

            items: [
              {
                year: 1996,
                text:
                  "Varga-Szárnyas Ltd. was founded in Tótszerdahely (Zala County).",
              },
              {
                year: 2001,
                text:
                  "Redevelopment, plant expansion, EU standards have been implemented",
              },
              {
                year: 2004,
                text: "Our feed mill was established.",
              },
              {
                year: 2008,
                text: "We expanded our capacity with 50%.",
              },
              {
                year: 2011,
                text:
                  "A new processing plant was installed as a greenfield investment.",
              },
              {
                year: 2019,
                text:
                  "We achieved our full technological mod- ernisation goals. We built a new packaging plant. Our company shows solid increase in its performance, the net turnover was HUF 6.3 billion.",
              },
              {
                year: 2021,
                text:
                  "Generational change, management restructuring, IT developments, preparation for the processing of 120-130 thousand chickens per week",
              },
            ],
          },
        },
      },
      {
        de: {
          title: "Über uns",
          desc:
            "Unser Familienunternehmen, gegründet im Jahr 1996, hat einen langen Weg zurückgelegt. Von einer kleineren Produktionseinheit, die manuell 400 Hühner pro Tag verarbeitete, haben wir jetzt eine Kapazität von 4000 Einheiten pro Stunde erreicht. Seit dem Anfang unserer Aktivitäten sind wir von einem starken und stetig zunehmenden Wettbewerb geprägt. Dementsprechend haben wir Effizienz und zuverlässiges Funktionieren zu unseren Kernwerten gemacht und streben kontinuierlich danach, die umfassenden Bedürfnisse unserer Partner zu erfüllen. Mit einem familiären Ansatz konzentrieren wir uns auf die Aufzucht von Küken, die Futtermittelproduktion, die Geflügelverarbeitung, die Produktverpackung und die Logistik. Unsere Arbeit ist immer durch kontinuierliche Verbesserung geprägt gewesen, und wir werden auch in Zukunft nach diesen Grundsätzen arbeiten.",
          quality: {
            title: "Qualitätssicherung",
            desc:
              "Die Varga Szárnyas Kft. engagiert sich für die Steigerung der Anerkennung ungarischer Lebensmittelprodukte, den Aufbau von Vertrauen bei unseren Partnern und die Sicherstellung des langfristigen Erfolgs des Unternehmens. Unser Lebensmittelsicherheitssystem arbeitet auf höchstem Niveau und bietet sowohl unseren Kunden als auch uns selbst Gewissheit und Nachweise durch organisierte und kontrollierte Prozesse, während jeder unserer Mitarbeiter persönliche Verantwortung für Lebensmittelsicherheit und gleichbleibende Qualität übernimmt.",
            desc2:
              "Wir verlassen uns vor allem auf unsere hochqualifizierten Fachleute in diesem Bereich. Unsere strengen Standards ermöglichen es uns, regelmäßig die Audit-Anforderungen multinationaler Einzelhandelsketten und HACCP zu erfüllen und zwei IFS-Zertifikate mit einer hervorragenden Bewertung zu besitzen. Neben der Herstellung hochwertiger Produkte haben wir auch eine stabile und transparente Finanzoperation als Priorität.",
            images: ["/images/quality/image-001.png"],
            sub_page: "quality",
          },
          productionUnits: {
            title: "Produktionsanlagen",
            desc:
              "Unsere Produktionsanlagen wurden auf einem 5 Hektar großen Gelände außerhalb der Stadtgrenzen von Becsehely, in der Nähe der ungarisch-kroatischen Grenze, errichtet. Die Einrichtungen, die unsere beiden Hauptaktivitäten, die Geflügelverarbeitung und die Futtermittelproduktion, beherbergen, befinden sich nebeneinander. Dank der benachbarten Standorte ist der Betrieb vollständig unter Kontrolle. In jüngster Zeit wurden Entwicklungen vorgenommen, um unseren Wettbewerbsvorteil zu erhöhen. Eine vollautomatisierte und datengetriebene Verarbeitungslinie wurde in unserem Verarbeitungsbetrieb installiert, sowohl im Bereich der Futtermittelproduktion als auch bei der Geflügelverarbeitung und Verpackung.",
            images: [],
          },
          timeline: {
            title: "Zeitverlauf",
            desc:
              "Wir begannen damit, die Bedürfnisse der benachbarten Siedlungen zu bedienen. Im Laufe der Jahre wurde unser Betrieb auf das Gebiet Ungarns ausgedehnt und wir sind schließlich in Exportmärkte eingetreten. 2/3 des Wertes unserer Geflügelprodukte werden an Kunden im Ausland verkauft. Derzeit gehören zu unseren Hauptexportmärkten Österreich, die Slowakei, Rumänien, Kroatien, Slowenien und Italien.",
            items: [
              {
                year: 1996,
                text:
                  "Varga-Szárnyas GmbH wurde in Tótszerdahely (Komitat Zala) gegründet.",
              },
              {
                year: 2001,
                text:
                  "Umstrukturierung, Werksausbau, Implementierung von EU-Standards.",
              },
              {
                year: 2004,
                text: "Unsere Futtermühle wurde errichtet.",
              },
              {
                year: 2008,
                text: "Wir haben unsere Kapazität um 50% erweitert.",
              },
              {
                year: 2011,
                text:
                  "Eine neue Verarbeitungsanlage wurde als Greenfield-Investition errichtet.",
              },
              {
                year: 2019,
                text:
                  "Unser Unternehmen hat seine Modernisierungsziele erreicht, einschließlich einer neuen Verpackungsanlage. Die Leistung ist solide gestiegen, der Nettoumsatz betrug 6,3 Milliarden HUF.",
              },
              {
                year: 2021,
                text:
                  "Generationenwechsel, Management-Umstrukturierung, IT-Entwicklungen, Vorbereitung auf die Verarbeitung von 120-130 Tausend Hühnern pro Woche",
              },
            ],
          },
        },
      },
      {
        sk: {
          title: "O nás",
          desc:
            "Náš rodinný podnik, založený v roku 1996, prešiel dlhú cestu. Z malého prevádzkového zariadenia, kde sme manuálne spracovávali 400 kurčiat denne, sme teraz dosiahli kapacitu 4000 jednotiek za hodinu. Od začiatku naša činnosť ovplyvňovala silná a stále sa zvyšujúca konkurencia. V dôsledku toho sme zaviedli efektivitu a predvídateľnú funkčnosť ako naše hlavné hodnoty a neustále sa snažíme uspokojiť komplexné potreby našich partnerov. S rodinným prístupom sa zameriavame na odchov nových kurčiat, výrobu krmív, spracovanie hydiny, balenie produktov a logistiku. Naša práca je vždy definovaná neustálym zlepšovaním a naďalej budeme podnikať na základe týchto zásad v budúcnosti.",
          quality: {
            title: "Záruka kvality",
            desc: "",
            desc2:
              "Našou hlavnou oporou sú naši vysoko kvalifikovaní odborníci v tejto oblasti. Naše prísne normy nám umožňujú pravidelne spĺňať audítorské požiadavky nadnárodných maloobchodných reťazcov, HACCP a máme tiež dve IFS certifikáty s vynikajúcim hodnotením. Okrem výroby vysoko kvalitných produktov uprednostňujeme aj stabilnú a transparentnú finančnú prevádzku.",
            images: ["/images/quality/image-001.png"],
            sub_page: "quality",
          },
          productionUnits: {
            title: "Výrobné zariadenia",
            desc:
              "Naše výrobné zariadenia sa nachádzajú na ploche 5 hektárov mimo hraníc obce Becsehely, blízko hranice medzi Maďarskom a Chorvátskom. Zariadenia pre naše dve hlavné oblasti činností, spracovanie hydiny a výrobu krmív, sú umiestnené vedľa seba. Vďaka susediacim lokalitám je prevádzka plne pod našou kontrolou. Nedávno sme uskutočnili vývoj s cieľom zvýšiť našu konkurenčnú výhodu - do našej spracovateľskej prevádzky sme nainštalovali plne automatizovanú a dátami riadenú linku, a to ako vo výrobe krmív, tak v spracovaní a balení hydiny.",
            images: [],
          },
          timeline: {
            title: "Časová os",
            desc:
              "Začali sme spokojne obsluhovať potreby susedných osád. V priebehu rokov sme rozšírili našu činnosť na územie Maďarska a nakoniec sme vstúpili na zahraničné trhy. 2/3 hodnoty našich hydinových produktov sa predáva zákazníkom v zahraničí. Momentálne patríme medzi hlavných exportných partnerov do krajín ako Rakúsko, Slovensko, Rumunsko, Chorvátsko, Slovinsko a Taliansko.",
            items: [
              {
                year: 1996,
                text:
                  "Spoločnosť Varga-Szárnyas Ltd. bola založená v Tótszerdahely (Zala County).",
              },
              {
                year: 2001,
                text: "Preznačenie, rozšírenie závodu, zavedenie noriem EÚ",
              },
              {
                year: 2004,
                text: "Bol založený náš krmný mlyn.",
              },
              {
                year: 2008,
                text: "Zvýšili sme svoju kapacitu o 50 %.",
              },
              {
                year: 2011,
                text:
                  "Bol nainštalovaný nový spracovateľský závod ako zelená investícia.",
              },
              {
                year: 2019,
                text:
                  "Dosiahli sme naše ciele plnej technologickej modernizácie. Postavili sme novú balírenskú halu. Náša spoločnosť preukazuje solídny nárast výkonu, čistý obrat bol 6,3 miliardy HUF.",
              },
              {
                year: 2021,
                text:
                  "Generacijska menjava, prestrukturiranje upravljanja, razvoj IT, priprava za obdelavo 120-130 tisoč piščancev na teden",
              },
            ],
          },
        },
      },
      {
        hr: {
          title: "O nama",
          desc:
            "Naše obiteljsko poduzeće, osnovano 1996. godine, prošlo je dug put. Od male operacije koja je ručno svakodnevno obrađivala 400 pilića, sada smo dostigli kapacitet od 4000 jedinica po satu. Od početka, naše poslovanje oblikuje snažna i sve intenzivnija konkurencija. Stoga smo efikasnost i predvidljivo funkcioniranje postavili kao temeljne vrijednosti, neprestano se trudeći zadovoljiti sveobuhvatne potrebe naših partnera. S obiteljskim pristupom usredotočeni smo na uzgoj jednodnevnih pilića, proizvodnju hrane, preradu peradi, pakiranje proizvoda i logistiku. Naš rad uvijek je obilježen neprestanim poboljšanjima i nastavit ćemo raditi na temelju tih načela i u budućnosti.",
          quality: {
            title: "Jamstvo kvalitete",
            desc:
              "Varga Szárnyas Kft. predano radi na povećanju prepoznatljivosti mađarskih prehrambenih proizvoda, izgradnji povjerenja s partnerima i osiguranju dugoročnog uspjeha tvrtke. Naš sustav sigurnosti hrane djeluje na najvišoj razini, pružajući sigurnost i dokaze kako našim kupcima, tako i sebi kroz organizirane i kontrolirane procese, dok svaki naš zaposlenik preuzima osobnu odgovornost za sigurnost hrane i dosljednu kvalitetu.",
            desc2:
              "Prije svega, oslanjamo se na naše visoko stručne profesionalce u ovom području. Naši strogi standardi omogućuju nam redovito zadovoljavanje zahtjeva revizija multinacionalnih trgovačkih lanaca, HACCP-a, a također posjedujemo dvije IFS certifikacije s odličnim rezultatima. Osim proizvodnje visokokvalitetnih proizvoda, prioritet nam je imati stabilno i transparentno financijsko poslovanje.",
            images: ["/images/quality/image-001.png"],
            sub_page: "quality",
          },
          productionUnits: {
            title: "Proizvodne pogone",
            desc:
              "Naši proizvodni pogoni smješteni su na površini od 5 hektara izvan gradskih granica Becsehelyja, blizu mađarsko-hrvatske granice. Pogoni koji su domaćini naše dvije glavne djelatnosti, preradu peradi i proizvodnju hrane, nalaze se jedan pored drugoga. Zahvaljujući susjedstvu, naše poslovanje je potpuno pod kontrolom. Nedavni razvoji događaju se kako bismo povećali našu konkurentsku prednost, u našem pogonu za preradu instalirana je potpuno automatizirana i podacima vođena proizvodna linija, kako u području proizvodnje hrane, tako i u području prerade peradi i pakiranja.",
            images: [],
          },
          timeline: {
            title: "Vremenska crta",
            desc:
              "Započeli smo zadovoljavanjem potreba susjednih naselja. Tijekom godina proširili smo naše poslovanje na područje Mađarske, a na kraju smo ušli na izvozna tržišta. 2/3 vrijednosti naših proizvoda od peradi prodaje se inozemnim kupcima. Trenutno naša glavna izvozna tržišta uključuju Austriju, Slovačku, Rumunjsku, Hrvatsku, Sloveniju i Italiju.",

            items: [
              {
                year: 1996,
                text:
                  "Varga-Szárnyas d.o.o. je osnovana u Tótszerdahelyju (Zala County).",
              },
              {
                year: 2001,
                text: "Obnova, proširenje postrojenja, primjena EU standarda.",
              },
              {
                year: 2004,
                text: "Osnovan je naš mlin za proizvodnju hrane.",
              },
              {
                year: 2008,
                text: "Povećali smo kapacitet za 50%.",
              },
              {
                year: 2011,
                text:
                  "Postavljena je nova pogonska postrojenja kao investicija iz zelene livade.",
              },
              {
                year: 2019,
                text:
                  "Postigli smo ciljeve potpune tehnološke modernizacije. Izgradili smo novu pogonsku postrojenja. Naše poduzeće pokazuje čvrsti porast performansi, neto promet je bio 6,3 milijarde HUF.",
              },
              {
                year: 2021,
                text:
                  "Generacijska promjena, restrukturiranje upravljanja, IT razvoj, priprema za obradu 120-130 tisuća pilećih peradi tjedno",
              },
            ],
          },
        },
      },
    ],

    aboutImages: [
      /* "/images/about/about-07.jpg",
      "/images/about/about-06.jpg", */
      "/images/about/about-08.jpg",
      "/images/about/about-04.jpg",
      "/images/about/about-09.jpg",
      "/images/about/about-05.jpg",
      "/images/about/about-10.jpg",
      "/images/about/about-03.jpg",
      "/images/about/about-02.jpg",
      "/images/quality/image-001.png",
      "/images/about/about-01.jpg",
    ],

    services: [
      {
        hu: {
          title: "Szolgáltatásaink",
          subtitle: "Megbízható megoldásokat nyújtva az Ön igényeihez.",
          services: [
            {
              title: "Naposcsibekeltetés",
              icon: "mdi mdi-egg",
              desc:
                "Szakértői szolgáltatásunk biztosítja a hatékony és gondos naposcsibék felnevelését, garantálva a kiváló minőségű és egészséges állatokat a kezdetektől fogva.",
            },
            {
              title: "Takarmánygyártás",
              icon: "mdi mdi-factory",
              desc:
                "A térség jelentős brojlercsirke termeltetője vagyunk. A teljes folyamatot figyelemmel kísérjük, a naposcsibék keltetése is családi kézben összpontosul. A nevelést szerződött partnereink végzik, mindannyian 100 kilométeren belül találhatóak. Az általunk felnevelt csirkét kizárólag a saját üzemünkben kevert takarmánnyal látjuk el. Nálunk ez garantálja az egyenletes és magas baromfihús-minőséget.",
            },
            {
              title: "Baromfifeldolgozás",
              icon: "mdi mdi-food-turkey",
              desc:
                "Teljeskörű baromfiipari feldolgozási szolgáltatásainkkal segítünk a friss és minőségi baromfihús előállításában, biztosítva az ügyfelek számára a legmagasabb minőséget és megbízhatóságot.",
            },
            {
              title: "Termékcsomagolás",
              icon: "mdi mdi-package-variant",
              desc:
                "Termékeinket különböző csomagolásban, többféle méretben készítjük elő:<br/> sztreccsfóliázott, vákuumcsomagolt, védőgázas tálcára csomagolt (MAP)",
            },
            {
              title: "Logisztika",
              icon: "mdi mdi-truck-delivery",
              desc:
                "Az Ön specifikus igényeitől függően hatékonyan és pontosan kezeljük vállalkozása ellátási láncát saját flottánkkal vagy szerződött szolgáltatóink segítségével, biztosítva a termékek gyors és pontos szállítását a célállomásokra.",
            },
            {
              title: "Online Rendelés",
              icon: "mdi mdi-cart-outline",
              desc:
                "Rugalmas és felhasználóbarát online rendelési rendszer, amely lehetővé teszi a könnyű és hatékony termékrendelést partnereink számára.",
            },
          ],
        },
      },
      {
        en: {
          title: "Our Services",
          subtitle: "Providing reliable solutions tailored to your needs.",
          services: [
            {
              title: "Day-Old Chick Rearing",
              icon: "mdi mdi-egg",
              desc:
                "Our expert service ensures the efficient and careful rearing of day-old chicks, guaranteeing excellent quality and healthy animals from the very beginning.",
            },
            {
              title: "Feed Production",
              icon: "mdi mdi-factory",
              desc:
                "We are a significant broiler chicken producer in the region. We closely monitor the entire process, including hatching the chicks in-house, while contracted partners within a 25-kilometer radius handle the rearing. Our chickens are exclusively fed with self-mixed feed in our facility, ensuring consistent and high-quality poultry meat.",
            },
            {
              title: "Poultry Processing",
              icon: "mdi mdi-food-turkey",
              desc:
                "With our comprehensive poultry processing services, we assist in the production of fresh and high-quality poultry meat, ensuring the highest level of quality and reliability for our customers.",
            },
            {
              title: "Product Packaging",
              icon: "mdi mdi-package-variant",
              desc:
                "We prepare our products in various packaging options and sizes, including stretch-wrapped, vacuum-sealed, and Modified Atmosphere Packaging (MAP).",
            },
            {
              title: "Logistics",
              icon: "mdi mdi-truck-delivery",
              desc:
                "Depending on your specific needs, we efficiently and accurately manage the supply chain of your business with our own fleet or through contracted service providers, ensuring fast and precise delivery of products to their destinations.",
            },
            {
              title: "Online Ordering",
              icon: "mdi mdi-cart-outline",
              desc:
                "A flexible and user-friendly online ordering system that enables easy and efficient product ordering for our partners.",
            },
          ],
        },
      },
      {
        de: {
          title: "Unsere Dienstleistungen",
          subtitle:
            "Zuverlässige Lösungen, maßgeschneidert für Ihre Bedürfnisse.",
          services: [
            {
              title: "Aufzucht von Küken",
              icon: "mdi mdi-egg",
              desc:
                "Unser Expertenservice gewährleistet eine effiziente und sorgfältige Aufzucht von Küken, um von Anfang an eine ausgezeichnete Qualität und gesunde Tiere zu garantieren.",
            },
            {
              title: "Futtermittelproduktion",
              icon: "mdi mdi-factory",
              desc:
                "Wir sind ein bedeutender Broilervogelproduzent in der Region. Wir überwachen den gesamten Prozess genau, einschließlich des Schlüpfens der Küken im Haus, während vertraglich vereinbarte Partner im Umkreis von 100 Kilometern die Aufzucht übernehmen. Unsere Hühner werden ausschließlich mit selbst gemischtem Futter in unserer Anlage gefüttert, um gleichbleibendes und qualitativ hochwertiges Geflügelfleisch zu gewährleisten.",
            },
            {
              title: "Geflügelverarbeitung",
              icon: "mdi mdi-food-turkey",
              desc:
                "Mit unseren umfassenden Geflügelverarbeitungsdienstleistungen unterstützen wir die Produktion von frischem und qualitativ hochwertigem Geflügelfleisch und gewährleisten dabei höchste Qualität und Zuverlässigkeit für unsere Kunden.",
            },
            {
              title: "Produktverpackung",
              icon: "mdi mdi-package-variant",
              desc:
                "Wir bereiten unsere Produkte in verschiedenen Verpackungsoptionen und Größen vor, darunter Stretchfolie, Vakuumverpackung und Modified Atmosphere Packaging (MAP).",
            },
            {
              title: "Logistik",
              icon: "mdi mdi-truck-delivery",
              desc:
                "Je nach Ihren spezifischen Anforderungen verwalten wir die Lieferkette Ihres Unternehmens effizient und präzise mit unserer eigenen Flotte oder über vertraglich vereinbarte Dienstleister und gewährleisten so eine schnelle und genaue Lieferung der Produkte an ihre Bestimmungsorte.",
            },
            {
              title: "Online-Bestellung",
              icon: "mdi mdi-cart-outline",
              desc:
                "Ein flexibles und benutzerfreundliches Online-Bestellsystem, das unseren Partnern eine einfache und effiziente Bestellung von Produkten ermöglicht.",
            },
          ],
        },
      },
      {
        sk: {
          title: "Naše služby",
          subtitle: "Poskytujeme spoľahlivé riešenia na mieru vašim potrebám.",
          services: [
            {
              title: "Chov novonarodených kurčiat",
              icon: "mdi mdi-egg",
              desc:
                "Naša odborná služba zaručuje efektívne a starostlivé chovanie novonarodených kurčiat, ktoré zaručuje vynikajúcu kvalitu a zdravých zvierat od samého začiatku.",
            },
            {
              title: "Výroba krmív",
              icon: "mdi mdi-factory",
              desc:
                "Sme významným producentom brojlerového kurčaťa v regióne. Úzko sledujeme celý proces, vrátane vyliahnutia kurčiat priamo u nás, zatiaľ čo zmluvní partneri vo vzdialenosti 100 kilometrov sa zaoberajú ich chovom. Naše kurčatá sú výhradne kŕmené samomiešaným krmivom vo vlastných zariadeniach, čo zaručuje konzistentnú a vysokokvalitnú drůbežinu.",
            },
            {
              title: "Spracovanie drůbeže",
              icon: "mdi mdi-food-turkey",
              desc:
                "S našimi komplexnými službami spracovania drůbeže pomáhame pri výrobe čerstvého a vysokokvalitného drůbežieho mäsa, čím zabezpečujeme najvyššiu úroveň kvality a spoľahlivosti pre našich zákazníkov.",
            },
            {
              title: "Balenie výrobkov",
              icon: "mdi mdi-package-variant",
              desc:
                "Pripravujeme naše výrobky v rôznych možnostiach balenia a veľkostiach, vrátane potiahnutého, vakuovo zabaleného a balenia v atmosfére s modifikovanou atmosférou (MAP).",
            },
            {
              title: "Logistika",
              icon: "mdi mdi-truck-delivery",
              desc:
                "V závislosti od vašich špecifických potrieb efektívne a presne riadime dodávateľský reťazec vášho podniku s našim vlastným vozovým parkom alebo cez zmluvných poskytovateľov služieb, čím zabezpečujeme rýchle a presné doručenie produktov na ich miesta určenia.",
            },
            {
              title: "Online objednávky",
              icon: "mdi mdi-cart-outline",
              desc:
                "Flexibilný a priateľský online systém objednávania, ktorý umožňuje jednoduché a efektívne objednávanie produktov pre našich partnerov.",
            },
          ],
        },
      },
      {
        hr: {
          title: "Naše usluge",
          subtitle:
            "Prilagođena rješenja pouzdano isporučena prema vašim potrebama.",
          services: [
            {
              title: "Održavanje jednodnevnih pilića",
              icon: "mdi mdi-egg",
              desc:
                "Naš stručni tim osigurava učinkovito i pažljivo uzgoj jednodnevnih pilića, jamčeći izvrsnu kvalitetu i zdrave životinje od samog početka.",
            },
            {
              title: "Proizvodnja hrane za perad",
              icon: "mdi mdi-factory",
              desc:
                "Mi smo značajan proizvođač pilećeg mesa u regiji. Pažljivo pratimo cijeli proces, uključujući i izleganje pilića u našem objektu, dok se odgoj obavlja putem ugovornih partnera unutar radijusa od 100 kilometara. Naše piliće hranimo isključivo sa vlastitom smjesom hrane u našem objektu, osiguravajući dosljedno i visokokvalitetno pileće meso.",
            },
            {
              title: "Prerada peradi",
              icon: "mdi mdi-food-turkey",
              desc:
                "S našim cjelovitim uslugama prerade peradi, pomažemo u proizvodnji svježeg i visokokvalitetnog pilećeg mesa, osiguravajući najvišu razinu kvalitete i pouzdanosti za naše kupce.",
            },
            {
              title: "Pakiranje proizvoda",
              icon: "mdi mdi-package-variant",
              desc:
                "Pripremamo naše proizvode u različitim opcijama pakiranja i veličinama, uključujući termoskupljajuću foliju, vakuumska pakiranja i modifikaciju atmosfere (MAP).",
            },
            {
              title: "Logistika",
              icon: "mdi mdi-truck-delivery",
              desc:
                "Ovisno o vašim specifičnim potrebama, učinkovito i precizno upravljamo lancom opskrbe vašeg poslovanja putem naše flote ili putem ugovorenih uslužnih pružatelja, osiguravajući brzu i preciznu dostavu proizvoda na njihova odredišta.",
            },
            {
              title: "Narudžba putem interneta",
              icon: "mdi mdi-cart-outline",
              desc:
                "Fleksibilan i jednostavan sustav narudžbi putem interneta koji omogućuje jednostavnu i učinkovitu narudžbu proizvoda za naše partnere.",
            },
          ],
        },
      },
    ],

    products: [
      {
        hu: {
          title: "Termékkínálat",
          subtitle: "",
          items: [
            {
              title: "Csirkehúsból készült termékeink",
              icon: "mdi mdi-food-turkey",
              desc:
                "Az alábbi termékszortiment friss és fagyasztott filét, egész combot, alsó- és felsőcombot, valamint a grilltestet súly szerint kalibrálva is elő tudjuk állítani.",
              products1: [
                "grilltest",
                "comb egészben",
                "alsócomb",
                "felsőcomb",
                "faros comb",
                "combfilé bőrrel vagy bőr nélkül",
                "felsőcomb-filé bőrrel vagy bőr nélkül",
                "mellcsonttal és bőrrel",
                "pillangó mellfilé belső mellfilével vagy belső mellfilé nélkül",
                "felezett mellfilé belső mellfilével vagy belső mellfilé nélkül",
                "belső mellfilé",
                "szeletelt mellfilé",
                "kockázott mellfilé",
                "csíkozott mellfilé",
                "farhát",
                "mellcsont",
                "szárny három ízületes",
                "szárny két ízületes (szárnytő és szárnyközép)",
                "szárnytő",
                "máj szívvel",
                "máj szív nélkül",
                "szív",
                "zúza",
                "nyak bőrrel vagy bőr nélkül",
                "láb",
                "melléktermékek állateledel gyártási célra",
              ],
              products: [
                {
                  name: "grilltest",
                  image: "Egesz_aprolek_nelkul.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "comb egészben",
                  image: "Comb.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "alsócomb",
                  image: "Alsocomb.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "felsőcomb",
                  image: "Felsocomb.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "faros comb",
                  image: "Comb_farresszel.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "combfilé bőrrel vagy bőr nélkül",
                  image: "Combfile_boros.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "felsőcomb-filé bőrrel vagy bőr nélkül",
                  image: "Felsocombfile_boros.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "mellfilé felezett szárnytővel (Supreme)",
                  image: "Mellfile_felezett_szarnytovel_Supreme.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name:
                    "pillangó mellfilé belső mellfilével vagy belső mellfilé nélkül",
                  image: "Mellfile_Pillango.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name:
                    "felezett mellfilé belső mellfilével vagy belső mellfilé nélkül",
                  image: "Mellfile_felezett_belsovel.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "belső mellfilé",
                  image: "Belso_mellfile.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "szeletelt mellfilé",
                  image: "Mellfile_szeletelt.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "kockázott mellfilé",
                  image: "",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "csíkozott mellfilé",
                  image: "",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "farhát",
                  image: "Farhat.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "mellcsont",
                  image: "Mellcsont.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "szárny három ízületes",
                  image: "Szarny.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "szárny két ízületes (szárnytő és szárnyközép)",
                  image: "Szarny_ketizuletes.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "szárnytő",
                  image: "Szarnyto.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "máj szívvel vagy szív nélkül",
                  image: "Maj_sziv_nelkul.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                // {
                //   name: "máj szív nélkül",
                //   image: "Máj szív nélkül.png",
                //   details: ["vakum", "suly szerint kalibralva is"],
                // },
                {
                  name: "szív",
                  image: "Sziv.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "zúza",
                  image: "Zuza.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "nyak bőrrel vagy bőr nélkül",
                  image: "Nyak_boros.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "láb",
                  image: "Lab.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                // {
                //   name: "melléktermékek állateledel gyártási célra",
                //   image: "",
                //   details: ["vakum", "suly szerint kalibralva is"],
                // },
              ],
            },
            /* {
              title: "Takarmánygyártás",
              icon: "mdi mdi-factory",
              desc:
                "A térség jelentős brojlercsirke termeltetője vagyunk. A teljes folyamatot figyelemmel kísérjük, a naposcsibék keltetése is családi kézben összpontosul. <br />A nevelést szerződött partnerink végzik, mindannyian 100 kilométeren belül találhatóak. Az általunk felnevelt csirkét kizárólag a saját üzemünkben kevert takarmánnyal látjuk el. Nálunk ez garantálja az egyenletes és magas baromfihús-minőséget.",
            }, */
          ],
        },
      },
      {
        en: {
          title: "Our Products",
          subtitle:
            "As a result of the developments, the company is capable of processing 8.3 million chickens annually in a single shift, of which we currently utilize 4.5 million units of capacity.",
          items: [
            {
              title: "Products Made from Chicken Meat",
              icon: "mdi mdi-food-turkey",
              desc:
                "The following product assortment includes fresh and frozen fillets, whole legs, drumsticks, and thigh cuts, calibrated by weight. We can produce the following items:",
              products1: [
                "grill test",
                "whole thigh",
                "lower thigh",
                "upper thigh",
                "drumstick",
                "thigh fillet with skin or without skin",
                "upper thigh fillet with skin or without skin",
                "with breastbone and skin",
                "butterfly breast fillet with inner breast fillet or without inner breast fillet",
                "halved breast fillet with inner breast fillet or without inner breast fillet",
                "inner breast fillet",
                "sliced breast fillet",
                "diced breast fillet",
                "striped breast fillet",
                "rear leg",
                "breastbone",
                "wing three-jointed",
                "wing two-jointed (wing base and wing middle)",
                "wing base",
                "liver with heart",
                "liver without heart",
                "heart",
                "gizzard",
                "neck with skin or without skin",
                "leg",
                "by-products for animal feed production",
              ],
              products: [
                {
                  name: "whole body",
                  image: "Egesz_aprolek_nelkul.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "whole thigh",
                  image: "Comb.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "lower thigh",
                  image: "Alsocomb.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "upper thigh",
                  image: "Felsocomb.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "drumstick",
                  image: "Comb_farresszel.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "thigh fillet with skin or without skin",
                  image: "Combfile_boros.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "upper thigh fillet with skin or without skin",
                  image: "Felsocombfile_boros.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "breast fillet halved with wing base (Supreme)",
                  image: "Mellfile_felezett_szarnytovel_Supreme.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name:
                    "butterfly breast fillet with or without inner breast fillet",
                  image: "Mellfile_Pillango.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name:
                    "halved breast fillet with or without inner breast fillet",
                  image: "Mellfile_felezett_belsovel.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "inner breast fillet",
                  image: "Belso_mellfile.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "sliced breast fillet",
                  image: "Mellfile_szeletelt.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "diced breast fillet",
                  image: "",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "striped breast fillet",
                  image: "",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "chicken back",
                  image: "Farhat.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "breastbone",
                  image: "Mellcsont.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "wing three-jointed",
                  image: "Szarny.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "wing two-jointed (wing base and wing middle)",
                  image: "Szarny_ketizuletes.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "wing base",
                  image: "Szarnyto.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "liver with heart",
                  image: "Maj_sziv_nelkul.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                // { name: "liver without heart", image: "Máj szív nélkül.png", details: ["vakum", "suly szerint kalibralva is"] },
                {
                  name: "heart",
                  image: "Sziv.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "gizzard",
                  image: "Zuza.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "neck with skin or without skin",
                  image: "Nyak_boros.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "leg",
                  image: "Lab.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                // {
                //   name: "by-products for animal feed production",
                //   image: "",
                //   details: ["vakum", "suly szerint kalibralva is"],
                // },
              ],
            },
            /* {
              title: "Production of feed",
              icon: "mdi mdi-factory",
              desc:
                "We are a significant broiler chicken producer in the region. We closely monitor the entire process, including the hatching of day-old chicks, which is done in a family-oriented environment. The rearing is carried out by our contracted partners, all located within a 25-kilometer radius. All the chicken they raise are fed by feed mixed in our plant. This ensures consistent high quality of our poultry.",
            }, */
          ],
        },
      },
      {
        de: {
          title: "Unsere Produkte",
          subtitle:
            "Als Ergebnis der Entwicklungen ist das Unternehmen in der Lage, jährlich 8,3 Millionen Hühner in einer Schicht zu verarbeiten, von denen wir derzeit 4,5 Millionen Kapazitätseinheiten nutzen.",
          items: [
            {
              title: "Produkte aus Hühnerfleisch",
              icon: "mdi mdi-food-turkey",
              desc:
                "Die folgende Produktpalette umfasst frische und tiefgefrorene Filets, ganze Beine, Hühnerschenkel und Oberschenkelschnitte, kalibriert nach Gewicht. Wir können folgende Artikel herstellen:",
              products1: [
                "Grilltest",
                "ganze Oberschenkel",
                "unterer Oberschenkel",
                "oberer Oberschenkel",
                "Schenkel",
                "Oberschenkel-Filet mit Haut oder ohne Haut",
                "oberes Oberschenkel-Filet mit Haut oder ohne Haut",
                "mit Brustbein und Haut",
                "Butterfly-Brustfilet mit innerem Brustfilet oder ohne inneres Brustfilet",
                "halbiertes Brustfilet mit innerem Brustfilet oder ohne inneres Brustfilet",
                "inneres Brustfilet",
                "geschnittenes Brustfilet",
                "gewürfeltes Brustfilet",
                "gestreiftes Brustfilet",
                "Hinterkeule",
                "Brustbein",
                "Flügel dreigelenkig",
                "Flügel zweigelenkig (Flügelansatz und Flügelmitte)",
                "Flügelansatz",
                "Leber mit Herz",
                "Leber ohne Herz",
                "Herz",
                "Kropf",
                "Hals mit Haut oder ohne Haut",
                "Bein",
                "Nebenprodukte für die Herstellung von Tierfutter",
              ],
              products: [
                {
                  name: "ganze Hähnchen",
                  image: "Egesz_aprolek_nelkul.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "ganze Oberschenkel",
                  image: "Comb.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "unterer Oberschenkel",
                  image: "Alsocomb.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "oberer Oberschenkel",
                  image: "Felsocomb.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "Schenkel",
                  image: "Comb_farresszel.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "Oberschenkel-Filet mit Haut oder ohne Haut",
                  image: "Combfile_boros.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "oberes Oberschenkel-Filet mit Haut oder ohne Haut",
                  image: "Felsocombfile_boros.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "Brustfilet halbiert mit Flügelansatz (Supreme)",
                  image: "Mellfile_felezett_szarnytovel_Supreme.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name:
                    "Butterfly-Brustfilet mit innerem Brustfilet oder ohne inneres Brustfilet",
                  image: "Mellfile_Pillango.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name:
                    "halbiertes Brustfilet mit innerem Brustfilet oder ohne inneres Brustfilet",
                  image: "Mellfile_felezett_belsovel.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "inneres Brustfilet",
                  image: "Belso_mellfile.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "geschnittenes Brustfilet",
                  image: "Mellfile_szeletelt.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "gewürfeltes Brustfilet",
                  image: "",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "gestreiftes Brustfilet",
                  image: "",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "Hinterkeule",
                  image: "Farhat.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "Brustbein",
                  image: "Mellcsont.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "Flügel dreigelenkig",
                  image: "Szarny.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "Flügel zweigelenkig (Flügelansatz und Flügelmitte)",
                  image: "Szarny_ketizuletes.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "Flügelansatz",
                  image: "Szarnyto.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "Leber mit Herz",
                  image: "Maj_sziv_nelkul.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                // { name: "Leber ohne Herz", image: "Máj szív nélkül.png", details: ["vakum", "suly szerint kalibralva is"] },
                {
                  name: "Herz",
                  image: "Sziv.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "Kropf",
                  image: "Zuza.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "Hals mit Haut oder ohne Haut",
                  image: "Nyak_boros.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                {
                  name: "Bein",
                  image: "Lab.png",
                  details: ["vakum", "suly szerint kalibralva is"],
                },
                // {
                //   name: "Nebenprodukte für die Herstellung von Tierfutter",
                //   image: "",
                //   details: ["vakum", "suly szerint kalibralva is"],
                // },
              ],
            },
            /* {
              title: "Futtermittelproduktion",
              icon: "mdi mdi-factory",
              desc:
                "Wir sind ein bedeutender Broilervogelproduzent in der Region. Wir überwachen den gesamten Prozess genau, einschließlich des Schlüpfens der Küken im Haus, der in einer familiären Umgebung durchgeführt wird. Die Aufzucht erfolgt durch unsere vertraglich vereinbarten Partner, die sich alle in einem Umkreis von 100 Kilometern befinden. Alle von ihnen aufgezogenen Hühner werden mit in unserer Anlage gemischtem Futter gefüttert. Dies gewährleistet eine gleichbleibend hohe Qualität unseres Geflügels.",
            }, */
          ],
        },
      },
      {
        hr: {
          title: "Naši Proizvodi",
          subtitle:
            "Kao rezultat razvoja, tvrtka je sposobna godišnje obraditi 8,3 milijuna pilića u jednoj smjeni, od čega trenutno koristimo kapacitet od 4,5 milijuna jedinica.",
          items: [
            {
              title: "Proizvodi od Piletine",
              icon: "mdi mdi-food-turkey",
              desc:
                "Sljedeća ponuda proizvoda uključuje svježe i smrznute filete, cijele noge, bataka i zabatke, kalibrirane prema težini. Proizvodimo sljedeće artikle:",
              products: [
                "gril tijelo",
                "cijela noga",
                "donja noga",
                "gornja noga",
                "stražnja noga",
                "batka s kožom ili bez kože",
                "gornja noga s kožom ili bez kože",
                "prsima s kostima i kožom",
                "fillet leptira s ili bez unutarnjeg filleta",
                "podijeljeni fillet s ili bez unutarnjeg filleta",
                "unutarnji filet",
                "rezani filet",
                "kockani filet",
                "prugasti filet",
                "leđa",
                "prsna kost",
                "tro-zglobno krilo",
                "dvije-zglobno krilo (s vrhom krila i središnjim zglobom krila)",
                "vrh krila",
                "jetra s srcem",
                "jetra bez srca",
                "srce",
                "želudac",
                "vrat s kožom ili bez kože",
                "noga",
                "nusproizvodi za proizvodnju hrane za životinje",
              ],
            },
            /* {
              title: "Proizvodnja hrane",
              icon: "mdi mdi-factory",
              desc:
                "Mi smo značajan proizvođač pilećeg mesa u regiji. Pažljivo pratimo cijeli proces, uključujući piljenje jednodnevnih pilića, što se odvija u obiteljskom okruženju. Odgoj provode naši ugovorni partneri, svi smješteni u radijusu od 100 kilometara. Sve piliće koje odgajaju hrane se smjesom koja se priprema u našem postrojenju. Time osiguravamo dosljednu visoku kvalitetu našeg pilećeg mesa.",
            }, */
          ],
        },
      },
      {
        sk: {
          title: "Naše výrobky",
          subtitle:
            "V dôsledku rozvoja je spoločnosť schopná spracovať 8,3 milióna kurčiat ročne v jednej zmenke, z čoho v súčasnosti využívame 4,5 milióna kapacitných jednotiek.",
          items: [
            {
              title: "Výrobky z hydinového mäsa",
              icon: "mdi mdi-food-turkey",
              desc:
                "Nasledujúci sortiment produktov zahŕňa čerstvé a zmrazené filé, celé stehná, párky a stehná, kalibrované podľa hmotnosti. Môžeme vyrábať nasledujúce položky:",
              products: [
                "grilovacie telo",
                "celé stehno",
                "nižšie stehno",
                "horné stehno",
                "zadné stehno",
                "stehienko s kožou alebo bez kože",
                "horné stehienko s kožou alebo bez kože",
                "prsia s kosťou a kožou",
                "motýľové filé s alebo bez vnútorného filé",
                "rozdelené filé s alebo bez vnútorného filé",
                "vnútorné filé",
                "plátkové filé",
                "nakrájané filé",
                "pruhované filé",
                "zadok",
                "hrudná kost",
                "trojklanné krídlo",
                "dvojklanné krídlo (koncovka krídla a stredu krídla)",
                "koncovka krídla",
                "pečeň srdcová",
                "pečeň bez srdcového tkaniva",
                "srdce",
                "žalúdok",
                "krk s kožou alebo bez kože",
                "noha",
                "vedľajšie produkty pre výrobu krmív pre zvieratá",
              ],
            },
            /* {
              title: "Výroba krmív",
              icon: "mdi mdi-factory",
              desc:
                "Sme významný producent brojlerového hydinského mäsa v regióne. Úplne sledujeme celý proces, vrátane líhnutia kurčiat, ktoré sa robí v rodinnom prostredí. Hydina sa chová našimi zmluvnými partnermi, ktorí sa nachádzajú v okruhu 100 kilometrov. Všetky kurčatá sú kŕmené krmivom z nášho závodu. To zaručuje konzistentne vysokú kvalitu našich hydinových výrobkov.",
            }, */
          ],
        },
      },
    ],

    factsData: [
      {
        hu: [
          {
            icon: "mdi-store-clock",
            value: 4000,
            decimalPlaces: null,
            separator: ".",
            prefix: "",
            suffix: "",
            text: "darab óránkénti kapacitás",
          },
          {
            icon: "mdi-sigma",
            value: 4.5,
            decimalPlaces: "1",
            separator: ".",
            prefix: "",
            suffix: " millió",
            text: "darab éves feldolgozás",
          },
          {
            icon: "mdi-factory",
            value: 8.3,
            decimalPlaces: 1,
            separator: ".",
            prefix: "",
            suffix: " millió",
            text: "csirke feldolgozási kapacitás",
          },
          {
            icon: "mdi-calendar-week",
            value: 90,
            decimalPlaces: "0",
            separator: ".",
            prefix: "+",
            suffix: " ezer",
            text: "darab csirke feldolgozás hetente",
          },
        ],
      },
      {
        en: [
          {
            icon: "mdi-store-clock",
            value: 4000,
            decimalPlaces: null,
            separator: ".",
            prefix: "",
            suffix: "",
            text: "Number of units per hour capacity",
          },
          {
            icon: "mdi-sigma",
            value: 4.5,
            decimalPlaces: "1",
            separator: ".",
            prefix: "",
            suffix: " million",
            text: "Units annual processing",
          },
          {
            icon: "mdi-factory",
            value: 8.3,
            decimalPlaces: 1,
            separator: ".",
            prefix: "",
            suffix: " million",
            text: "Chicken processing capacity",
          },
          {
            icon: "mdi-calendar-week",
            value: 90,
            decimalPlaces: "0",
            separator: ".",
            prefix: "+",
            suffix: " thousand",
            text: "weekly chicken processing units",
          },
          /* {
            icon: "mdi-point-of-sale",
            value: 6.3,
            decimalPlaces: "1",
            separator: ".",
            prefix: "+",
            suffix: " billion",
            text: "Annual revenue (2019)",
          }, */
        ],
      },
      {
        de: [
          {
            icon: "mdi-store-clock",
            value: 4000,
            decimalPlaces: null,
            separator: ".",
            prefix: "",
            suffix: "",
            text: "Anzahl der Einheiten pro Stunde Kapazität",
          },
          {
            icon: "mdi-sigma",
            value: 4.5,
            decimalPlaces: "1",
            separator: ".",
            prefix: "",
            suffix: " Million",
            text: "Jährliche Verarbeitungseinheiten",
          },
          {
            icon: "mdi-factory",
            value: 8.3,
            decimalPlaces: 1,
            separator: ".",
            prefix: "",
            suffix: " Million",
            text: "Hühnchenverarbeitungskapazität",
          },
          {
            icon: "mdi-calendar-week",
            value: 120,
            decimalPlaces: "0",
            separator: ".",
            prefix: "+",
            suffix: " Tausend",
            text: "wöchentliche Hähnchenverarbeitungseinheiten",
          },
          /* {
            icon: "mdi-point-of-sale",
            value: 6.3,
            decimalPlaces: "1",
            separator: ".",
            prefix: "+",
            suffix: " Milliarden",
            text: "Jahresumsatz (2019)",
          }, */
        ],
      },
      {
        hr: [
          {
            icon: "mdi-store-clock",
            value: 4000,
            decimalPlaces: null,
            separator: ".",
            prefix: "",
            suffix: "",
            text: "Broj jedinica po satu kapaciteta",
          },
          {
            icon: "mdi-sigma",
            value: 4.5,
            decimalPlaces: "1",
            separator: ".",
            prefix: "",
            suffix: " milijun",
            text: "Godišnja obrada jedinica",
          },
          {
            icon: "mdi-factory",
            value: 8.3,
            decimalPlaces: 1,
            separator: ".",
            prefix: "",
            suffix: " milijun",
            text: "Kapacitet prerade piletine",
          },
          {
            icon: "mdi-calendar-week",
            value: 90,
            decimalPlaces: "0",
            separator: ".",
            prefix: "+",
            suffix: " tisuća",
            text: "tjedne jedinice obrade pilećeg mesa",
          },
          /* {
            icon: "mdi-point-of-sale",
            value: 6.3,
            decimalPlaces: "1",
            separator: ".",
            prefix: "+",
            suffix: " milijarda",
            text: "Godišnji prihod (2019)",
          }, */
        ],
      },
      {
        sk: [
          {
            icon: "mdi-store-clock",
            value: 4000,
            decimalPlaces: null,
            separator: ".",
            prefix: "",
            suffix: "",
            text: "Počet kapacity jednotiek za hodinu",
          },
          {
            icon: "mdi-sigma",
            value: 4.5,
            decimalPlaces: "1",
            separator: ".",
            prefix: "",
            suffix: " milióna",
            text: "Ročné spracovanie jednotiek",
          },
          {
            icon: "mdi-factory",
            value: 8.3,
            decimalPlaces: 1,
            separator: ".",
            prefix: "",
            suffix: " milióna",
            text: "Kapacita spracovania hydiny",
          },
          {
            icon: "mdi-calendar-week",
            value: 120,
            decimalPlaces: "0",
            separator: ".",
            prefix: "+",
            suffix: " tisíc",
            text: "týždenné spracovateľské jednotky kurčiat",
          },
          /* {
            icon: "mdi-point-of-sale",
            value: 6.3,
            decimalPlaces: "1",
            separator: ".",
            prefix: "+",
            suffix: " miliardy",
            text: "Ročný príjem (2019)",
          }, */
        ],
      },
    ],

    abuseText: [
      {
        hu: [
          "BELSŐ VISSZAÉLÉS-BEJELENTÉSI RENDSZER",
          "Visszaélés-bejelentéséhez kérjük töltse ki az űrlapot!",
        ],
      },
    ],

    high5link: [
      {
        hu: [
          "R-AI-VQC: REACH Agrifood AI által támogatott vizuális minőségellenőrzés",
          ,
          "2023.11.01.",
        ],
      },
      {
        en: [
          "R-AI-VQC: REACH Agrifood AI által támogatott vizuális minőségellenőrzés",
          ,
          "2023.11.01.",
        ],
      },
    ],

    call2action: [
      {
        hu: [
          "Ismerje meg baromfitermékeinket! Forduljon hozzánk <br/> exkluzív ajánlatokért és az Ön igényeihez igazított árakért!",
          "Engedje, hogy vállalkozását kiemelkedő minőségű csirketermékekkel fejlesszük.<br /> Lépjen kapcsolatba velünk most, és kérjen árajánlatot!",
        ],
      },
      {
        en: [
          "Discover our poultry products! <br/> Contact us for exclusive offers and prices tailored to your needs!",
          "Let us elevate your business with outstanding quality chicken products.<br/>Contact us now and request a quote!",
        ],
      },
      {
        de: [
          "Entdecken Sie unsere Geflügelprodukte! <br/> Kontaktieren Sie uns für exklusive Angebote und Preise, die auf Ihre Bedürfnisse zugeschnitten sind!",
          "Lassen Sie uns Ihr Unternehmen mit herausragenden Qualitäts-Hühnerprodukten bereichern. <br/> Kontaktieren Sie uns jetzt und fordern Sie ein Angebot an!",
        ],
      },
      {
        sk: [
          "Objavte naše drůbeží výrobky! <br/> Kontaktujte nás a získejte exkluzivní nabídky a ceny přizpůsobené vašim potřebám!",
          "Nechte nás zvýšit úroveň vašeho podnikání s vynikajícími kuřecími produkty.<br/>Nyní nás kontaktujte a požádejte o cenovou nabídku!",
        ],
      },
      {
        hr: [
          "Otkrijte naše peradarske proizvode! <br/> Kontaktirajte nas za ekskluzivne ponude i cijene prilagođene vašim potrebama!",
          "Dopustite nam da podignemo vaše poslovanje s izvrsnim kvalitetnim proizvodima od piletine.<br/>Sada nas kontaktirajte i zatražite ponudu!",
        ],
      },
    ],

    footers: [
      {
        hu: {
          address: "8866 Becsehely,<br/>Kossuth Lajos u. 254.",
          phone: [
            {
              label: "Iroda",
              value: "+36 93 900 400",
            },
          ],
          email: [
            {
              label: "Iroda",
              text: "rendeles@vargaszarnyas.hu",
              href: "mailto://rendeles@vargaszarnyas.hu",
            },
            {
              label: "Online Rendelés",
              text: "rendeles.vargaszarnyas.hu",
              href: "https://rendeles.vargaszarnyas.hu",
            },
          ],
          social: [
            {
              icon: "mdi-facebook",
              href:
                "https://www.facebook.com/Varga-Sz%C3%A1rnyas-Kft-304940322979328/",
            },
          ],
        },
      },
      {
        en: {
          address: "8866 Becsehely,<br/>Kossuth Lajos u. 254.",
          phone: [
            {
              label: "Office",
              value: "+36 93 900 400",
            },
          ],
          email: [
            {
              label: "Office",
              text: "rendeles@vargaszarnyas.hu",
              href: "mailto://rendeles@vargaszarnyas.hu",
            },
            {
              label: "Online Order",
              text: "rendeles.vargaszarnyas.hu",
              href: "https://rendeles.vargaszarnyas.hu",
            },
          ],
          social: [
            {
              icon: "mdi-facebook",
              href:
                "https://www.facebook.com/Varga-Sz%C3%A1rnyas-Kft-304940322979328/",
            },
          ],
        },
      },
      {
        de: {
          address: "8866 Becsehely,<br/>Kossuth Lajos u. 254.",
          phone: [
            {
              label: "Büro",
              value: "+36 93 900 400",
            },
          ],
          email: [
            {
              label: "Büro",
              text: "rendeles@vargaszarnyas.hu",
              href: "mailto://rendeles@vargaszarnyas.hu",
            },
            {
              label: "Online-Bestellung",
              text: "rendeles.vargaszarnyas.hu",
              href: "https://rendeles.vargaszarnyas.hu",
            },
          ],
          social: [
            {
              icon: "mdi-facebook",
              href:
                "https://www.facebook.com/Varga-Sz%C3%A1rnyas-Kft-304940322979328/",
            },
          ],
        },
      },
      {
        sk: {
          address: "8866 Becsehely,<br/>Kossuth Lajos u. 254.",
          phone: [
            {
              label: "Kancelária",
              value: "+36 93 900 400",
            },
          ],
          email: [
            {
              label: "Kancelária",
              text: "rendeles@vargaszarnyas.hu",
              href: "mailto://rendeles@vargaszarnyas.hu",
            },
            {
              label: "Online Objednávka",
              text: "rendeles.vargaszarnyas.hu",
              href: "https://rendeles.vargaszarnyas.hu",
            },
          ],
          social: [
            {
              icon: "mdi-facebook",
              href:
                "https://www.facebook.com/Varga-Sz%C3%A1rnyas-Kft-304940322979328/",
            },
          ],
        },
      },
      {
        hr: {
          address: "8866 Becsehely,<br/>Kossuth Lajos u. 254.",
          phone: [
            {
              label: "Ured",
              value: "+36 93 900 400",
            },
          ],
          email: [
            {
              label: "Ured",
              text: "rendeles@vargaszarnyas.hu",
              href: "mailto://rendeles@vargaszarnyas.hu",
            },
            {
              label: "Online Narudžba",
              text: "rendeles.vargaszarnyas.hu",
              href: "https://rendeles.vargaszarnyas.hu",
            },
          ],
          social: [
            {
              icon: "mdi-facebook",
              href:
                "https://www.facebook.com/Varga-Sz%C3%A1rnyas-Kft-304940322979328/",
            },
          ],
        },
      },
    ],

    qualityText: [
      {
        hu: {
          text: [
            "Élelmiszer biztonsági rendszerünkkel megrendelőink megelégedettségén túl szeretnénk elérni, hogy partnereink előtt példa értékű legyen a tevékenységünk színvonala, az élelmiszer biztonsága, mellyel a magyar élelmiszer termékek elismertségéhez, annak fokozásához kívánunk hozzájárulni.",
            "Magas szinten kívánjuk kielégíteni partnereink elvárt és kifejezett igényeit, növelve ezzel bizalmukat cégünk iránt. Szeretnénk, ha szolgáltatásink és termékeink megfelelő elismerést vívnának ki fokozatosan bővülő megrendelőink körében. Tudatosítani szeretnénk bennük, hogy a Varga Szárnyas Kft. által előállított áruk kiváló minőségűek és megfelelnek a legszigorúbb jogszabályi követelményeknek az élelmiszer biztonság és az elvárt minőség tekintetében.",
            "Legfőbb célunk vevőink, partnereink élelmiszer biztonság iránti igényének kielégítése hosszútávon, biztosítva ezzel a társaság eredményes működését, öregbítve hírnevét, megtartva versenyképességét és ezzel biztosítva tartós fennmaradását, alkalmazottainak biztos és megfelelő életkörülményeit.",
            "HACCP -, valamint a magas szinten kiépített és működtetett IFS FOOD és HALAL szabvány követelményeinek megfelelő élelmiszerbiztonsági rendszerünkkel biztosítékot és bizonyítékot kívánunk gyűjteni és szolgáltatni megrendelőink és önmagunk számára, hogy folyamataink szervezetten és ellenőrzötten történnek. Minden ésszerű óvintézkedést megteszünk a biztonságos élelmiszer előállítás, ezáltal a fogyasztóink védelmének biztosítására valamint az elvárt vevői követelmények kielégítésére.",
            "Munkatársainkat folyamatosan képezzük, az élelmiszer-biztonsági témakörben tett visszajelzéseiket figyelembe vesszük, kezeljük.",
            "Az átlátható és ellenőrizhető nyomonkövetési rendszerünknek köszönhetően a termelőtől a végső fogyasztóig minden ponton biztosítani tudjuk az elvárható legmagasabb minőséget.",
            "Bevezetett rendszerünkkel célunk, hogy az üzemi tevékenységben résztvevő valamennyi munkatársunk tudatában legyen az élelmiszer biztonsági követelményeknek és be is tartsák azokat, személyes felelősséget vállalva munkájukért, az élelmiszer higiéniai biztonságért. Ezt külső szakemberek és saját minőségbiztosítási stábunk is folyamatosan ellenőrzi és auditálja.",
            "A Varga Szárnyas Kft. felsővezetése elkötelezi magát minőségirányítási, élelmiszer-biztonsági, környezetirányítási és HALAL rendszerek kialakítása és fenntartása mellett, és az ezekben foglaltakat személyes példamutatásával, a munkatársak teljes körű bevonásával valósítja meg. Feladatának tekinti az élelmiszer biztonság politika megismertetését és megértetését a dolgozókkal, üzleti partnerekkel.",
          ],
          link: "/certificate/certificate_en_2023-compressed.pdf",
        },
        en: {
          text: [
            "With our food safety system, we aim not only to satisfy our customers but also to set an example of the quality of our activities and the food safety measures we contribute to for the recognition and enhancement of Hungarian food products.",
            "We aim to meet our partners' expected and expressed needs at a high level, thereby increasing their trust in our company. We would like our services and products to gradually gain proper recognition among our expanding clientele. We want to ensure they understand that the goods produced by Varga Szárnyas Kft. are of excellent quality and comply with the strictest legal requirements in terms of food safety and expected quality.",
            "Our primary goal is to satisfy the long-term demand for food safety from our customers and partners, thereby ensuring the successful operation of the company, enhancing its reputation, maintaining its competitiveness, and ensuring the long-term well-being of its employees.",
            "Withour food safety system complying with HACCP, as well as the highly established and operated IFS FOOD and HALAL standards, we aim to gather and provide assurance and evidence for both our clients and ourselves that our processes are organized and controlled. We take all reasonable precautions to ensure the production of safe food, thereby protecting our consumers and meeting the expected customer requirements.",
            "We continuously train our employees and take into account and address their feedback on food safety topics.",
            "Thanks to our transparent and traceable tracking system, we can ensure the highest expected quality at every point from the producer to the final consumer.",
            "With our established system, our goal is for all employees involved in the operational activities to be aware of food safety requirements and adhere to them, taking personal responsibility for their work and food hygiene safety. External experts and our own quality assurance team continuously monitor and audit this.",
            "The top management of Varga Szárnyas Kft. is committed to establishing and maintaining quality management, food safety, environmental management, and HALAL systems, and implements these through personal example and full involvement of employees. They consider it their duty to familiarize and ensure the understanding of the food safety policy among employees and business partners.",
          ],
          link: "/certificate/certificate_en_2023-compressed.pdf",
        },
        de: {
          text: [
            "Mit unserem Lebensmittelsicherheitssystem streben wir nicht nur die Zufriedenheit unserer Kunden an, sondern auch, dass unsere Partner von der Qualität unserer Arbeit und der Lebensmittelsicherheit, zu der wir zu den ungarischen Lebensmittelprodukten beitragen möchten, beeindruckt sind.",
            "Wir möchten auf hohem Niveau die Erwartungen und ausdrücklichen Anforderungen unserer Partner erfüllen und damit ihr Vertrauen in unser Unternehmen stärken. Wir wünschen uns, dass unsere Dienstleistungen und Produkte das angemessene Anerkennung bei unseren zunehmend wachsenden Kundenkreis finden. Wir möchten ihnen bewusst machen, dass die von der Varga Szárnyas Kft. hergestellten Waren von ausgezeichneter Qualität sind und den strengsten gesetzlichen Anforderungen in Bezug auf Lebensmittelsicherheit und die erwartete Qualität entsprechen.",
            "Unser Hauptziel ist die langfristige Erfüllung der Nachfrage unserer Kunden und Partner nach Lebensmittelsicherheit, wodurch der erfolgreiche Betrieb des Unternehmens sichergestellt wird. Wir möchten den Ruf des Unternehmens verbessern, seine Wettbewerbsfähigkeit erhalten und damit seine langfristige Existenz und die sicheren und angemessenen Lebensbedingungen seiner Mitarbeiter gewährleisten.",
            "Mit unserem HACCP-System sowie dem hochentwickelten und betriebenen IFS FOOD- und HALAL-Standard wollen wir Sicherheit und Nachweis für unsere Kunden und uns selbst gewährleisten, dass unsere Prozesse organisiert und kontrolliert ablaufen. Wir ergreifen alle vernünftigen Vorsichtsmaßnahmen, um die sichere Herstellung von Lebensmitteln und damit den Schutz unserer Verbraucher sowie die Erfüllung der erwarteten Kundenanforderungen zu gewährleisten.",
            "Wir schulen unsere Mitarbeiter kontinuierlich und berücksichtigen sowie bearbeiten ihr Feedback im Bereich Lebensmittelsicherheit.",
            "Dank unseres transparenten und nachverfolgbaren Systems können wir an jedem Punkt, vom Hersteller bis zum Endverbraucher, die erwartete höchste Qualität gewährleisten.",
            "Mit unserem eingeführten System möchten wir sicherstellen, dass alle Mitarbeiter, die an den betrieblichen Aktivitäten beteiligt sind, sich der Anforderungen an die Lebensmittelsicherheit bewusst sind und diese einhalten, indem sie persönliche Verantwortung für ihre Arbeit und die Lebensmittelsicherheit übernehmen. Externe Fachleute und unser eigenes Qualitätsmanagementteam überwachen und überprüfen dies kontinuierlich.",
            "Die Geschäftsführung der Varga Szárnyas Kft. verpflichtet sich zur Einführung und Aufrechterhaltung von Qualitätsmanagement-, Lebensmittelsicherheits-, Umweltmanagement- und HALAL-Systemen und setzt diese durch persönliches Vorbild und die umfassende Einbeziehung der Mitarbeiter um. Sie betrachtet es als ihre Aufgabe, die Lebensmittelsicherheitspolitik den Mitarbeitern und Geschäftspartnern bekannt zu machen und ihr Verständnis dafür zu fördern.",
          ],
          link: "/certificate/certificate_en_2023-compressed.pdf",
        },
      },
    ],
  }),

  // optional getters
  getters: {},
  // optional actions
  actions: {
    async sendContactForm(body: {}) {
      //const { baseURL, token } = useApifetch();
      const baseURL: any = useRuntimeConfig().public.api_url;

      //console.log(baseURL);

      const formdata: any = await $fetch(`${baseURL}/biz/web-contact`, {
        method: "POST",
        body: body,
        /* headers: {
          Authorization: `Bearer ${token}`,
        }, */
      });

      return formdata;
    },

    async sendAbuseReportForm(body: {}) {
      //const { baseURL, token } = useApifetch();
      const baseURL: any = useRuntimeConfig().public.api_url;

      //console.log(baseURL);

      const formdata: any = await $fetch(`${baseURL}/biz/web-abuse-report`, {
        method: "POST",
        body: body,
        /* headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        }, */
      });

      return formdata;
    },
  },
});
